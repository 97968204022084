import { extendTheme, withDefaultColorScheme, theme as base } from '@chakra-ui/react';
// import '@fontsource/barlow';
// import '@fontsource/open-sans';

const theme = extendTheme({
  colors: {
    primary: {
      // 50: '#fff5f9',
      // 100: '#ffd1e3',
      // 200: '#ffabcd',
      // 300: '#ff78ae',
      // 400: '#ff599c',
      // 500: '#fa3282',
      // 600: '#d42a6e',
      // 700: '#ab2259',
      // 800: '#8f1d4a',
      // 900: '#691536',
      // 50: '#fff5f7',
      // 100: '#ffccd8',
      // 200: '#ffb2c6',
      // 300: '#ff8ca9',
      // 400: '#ff809f',
      // 500: '#fa6489',
      // 600: '#d45574',
      // 700: '#ab3f5a',
      // 800: '#8f3249',
      // 900: '#692536',
      // 50: '#f4fbfc',
      // 100: '#d2eef4',
      // 200: '#bde7ef',
      // 300: '#a5dee9',
      // 400: '#5cc2d7',
      // 500: '#36b4ce',
      // 600: '#27a3bc',
      // 700: '#20879b',
      // 800: '#1a6f80',
      // 900: '#145663',
      // 50: '#f3f8fc',
      // 100: '#cee3f4',
      // 200: '#a3caeb',
      // 300: '#6dacdf',
      // 400: '#4e9ad8',
      // 500: '#2c81c7',
      // 600: '#256da8',
      // 700: '#1e5887',
      // 800: '#194a72',
      // 900: '#123652',
      50: '#fcf2f4',
      100: '#fad9de',
      200: '#f5b5be',
      300: '#ed8a97',
      400: '#ec4c60',
      500: '#ec4c60',
      600: '#d4344c',
      700: '#a82236',
      800: '#8f1d2e',
      900: '#691521',
    },
    bright: {
      500: '#ec4c60',
      600: '#d4344c',
      700: '#a82236',
    },
    light: '#f5f5f5',
    lightAccent: '#B0A4A7',
    dark: '#1A1A1A',
    darkAccent: '#8f8588',
  },
  fonts: {
    heading: `Lato, sans-serif`,
    body: `Lato, sans-serif`,
  },
  components: {
    Button: {
      sizes: {
        sm: {
          fontSize: 'xs',
        },
        md: {
          fontSize: 'sm',
        },
        lg: {
          fontSize: 'md',
        },
      },
    },
  },
});

export default theme;
