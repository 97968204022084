const isReactNative = typeof __DEV__ !== 'undefined';
const isDev = process.env.NODE_ENV === 'development' || (isReactNative ? __DEV__ : false);

const devBaseUrl = 'http://127.0.0.1:8000';
// NOTE. to test on local android device set API_URL to the IP in Network Settings -> Wifi
// const baseUrl = 'http://10.0.2.2:8000';

const devConfig = {
  IS_REACT_NATIVE: isReactNative,
  IS_PRODUCTION: false,
  API_URL: `${devBaseUrl}/api`,
  MEDIA_URL: `${devBaseUrl}/media`,
};

const prodConfig = {
  IS_REACT_NATIVE: isReactNative,
  IS_PRODUCTION: true,
  API_URL: 'https://api.socialtable.app/api',
  MEDIA_URL: `https://tlsocial.s3.amazonaws.com/m`,
};

export const env = isDev ? devConfig : prodConfig;
