import React from 'react';
import * as c from '../../constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import Skeleton from 'react-loading-skeleton';
import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import { MagicLink } from 'components/shared/Links';
import { linksTo } from 'constants';
import * as utils from '../../utils';

const UpcomingItem = props => {
  const { item } = props;

  const cityTimeEvent = lib.toZoned(item.time_event, item.city_timezone);
  const linkRoute = item.is_proposal ? linksTo.PROPOSAL(item) : linksTo.POOL(item);

  return (
    <MagicLink {...linkRoute} backgroundColor="white" overflow="hidden" rounded="10" p={5} h="full">
      <Box>
        <HStack alignItems="flex-start" spacing={0}>
          <Box borderRightWidth={1} borderColor="silver" pr={4} mt="3px">
            <Text textAlign="center" fontSize={13}>
              {format(cityTimeEvent, 'eee')}
            </Text>
            <Text fontWeight="bold" textAlign="center" fontSize={20} opacity={0.7}>
              {format(cityTimeEvent, 'dd')}
            </Text>
          </Box>
          <Box>
            <Text fontSize={18} fontWeight="bold" mb={0} pl={4} flex={1}>
              {item.title}
            </Text>
            <Text fontSize={16} mb={1} mt="2px" pl={4} flex={1} noOfLines={1} maxW="600px">
              {item.description}
            </Text>
          </Box>
        </HStack>
      </Box>
    </MagicLink>
  );
};

export default UpcomingItem;
