import { useEffect } from 'react';
import { bugsnagClearUser, bugsnagNotify, bugsnagSetUser } from '../platformConfig';
import { env } from './libEnvironment';

export const useSetBugUser = user => {
  useEffect(() => {
    if (env.IS_PRODUCTION) {
      bugsnagSetUser(user.id.toString(), user.email, user.full_name);
    }
    return () => bugsnagSetUser(null);
  }, [user.id]);
};

export const error = error => {
  if (env.IS_PRODUCTION) {
    bugsnagNotify(error);
  }
};

export const errorString = errorString => {
  error(new Error(errorString));
};
