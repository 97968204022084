export const tags = {
  USER: 'user',
  USER_DATA: 'userData',
  PAYMENT_METHODS: 'paymentMethods',
  POOLS: 'pools',
  USER_APPRECIATIONS: 'userAppreciations',
  BELLS: 'bells',
  MY_PROPOSALS: 'myProposals',
  CITY_PROPOSALS: 'cityProposals',
  MY_INVITES: 'myInvites',
  CURRENT_USER_EVENTS: 'currentUserEvents',
  CHATS: 'chats',
  CITY: 'city',
  CONTACTS: 'contacts',
};
