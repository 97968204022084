import React from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { useModal, useModalConfirm } from 'hooks';
import Button from './Button';
import { H1, H2, H3 } from './Headings';

export const BaseModal = props => {
  const { title, body } = props;

  return (
    <VStack spacing={3}>
      {title && <H3 textAlign="center">{title}</H3>}
      {body && <Text textAlign="center">{body}</Text>}
      {props.children}
    </VStack>
  );
};

export const ConfirmModal = props => {
  const { body, warning } = props;
  const modal = useModalConfirm();
  const title = props.title || 'Are you sure?';
  const confirmText = props.confirmText || 'Yes';
  const cancelText = props.cancelText || 'Cancel';

  return (
    <BaseModal title={title} body={body}>
      <HStack spacing={3} w="full">
        <Button
          secondary
          warning={warning}
          isLoading={modal.confirmLoading}
          label={confirmText}
          onClick={modal.confirm}
        />
        <Button secondary onClick={modal.close} label={cancelText} />
      </HStack>
    </BaseModal>
  );
};
