export const slugify = str => {
  return str
    .trim()
    .toLowerCase()
    .replace(/[\W_]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

export const toSlug = (str, id) => {
  return `${slugify(str)}-${id}`;
};

export const fromSlug = str => {
  const parts = str.split('-');
  return parts[parts.length - 1];
};
