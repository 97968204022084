import { env } from './libEnvironment';
import { EVENT_TYPE_DATA } from './libEventType';
import { images as platformImages } from '../platform/images';

export const images = platformImages;

export const getObjImageUrl = (obj, folder, size, imageKey = null) => {
  const key = imageKey || obj.image_key;
  return `${env.MEDIA_URL}/${folder}/${obj.id}/${key}_${size}.jpg`;
};

export const getUserImageUrlSimple = (userId, size) => `${env.MEDIA_URL}/users/${userId}_${size}.jpg`;

export const getUserImageUrl = (obj, size) => getObjImageUrl(obj, 'users', size);
export const getCityImageUrl = (obj, size, imageKey) => getObjImageUrl(obj, 'cities', size, imageKey);
export const getUpdateImageUrl = obj => getObjImageUrl(obj, 'updates', 'large');
export const getLocationImageUrl = obj => getObjImageUrl(obj, 'locations', 'large');
export const getProposalImageUrl = obj => getObjImageUrl(obj, 'proposals', 'large');

export const getPoolImageSource = (pool, city) => {
  if (pool.location?.has_image) {
    return getLocationImageSource(pool.location);
  } else {
    const typeData = EVENT_TYPE_DATA[pool.event_type];
    const poolKey = city[`image_${typeData.fieldName}_key`];
    if (poolKey) {
      return getCityImageUrl(city, 'large', poolKey);
    } else {
      return getCityImageSource(city);
    }
  }
};

export const getProposalImageSource = proposal => {
  if (proposal.has_image) {
    return getProposalImageUrl(proposal);
  } else if (proposal.location) {
    return getLocationImageSource(proposal.location);
  } else {
    return getCityImageSource(city);
  }
};

export const getEventImageSource = event => {
  if (event) {
    if (event.proposal) {
      return getProposalImageSource(event.proposal);
    } else if (event.pool) {
      return getPoolImageSource(event.pool);
    } else {
      return images.above;
    }
  }
};

export const getLocationImageSource = location => {
  if (location?.has_image) {
    return getLocationImageUrl(location);
  }
};

export const getCityImageSource = city => {
  if (city?.has_image) {
    return getCityImageUrl(city, 'xlarge');
  } else {
    return images.above;
  }
};

export const getProfileImageSource = (profile, size) => {
  if (profile.image_key) {
    return getUserImageUrl(profile, size)
  } else {
    return images.blankProfile
  }
}