import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { chakra } from '@chakra-ui/react';

const Icon = props => {
  const ChakraIcon = chakra(FontAwesomeIcon);
  return <ChakraIcon {...props} fixedWidth />;
};

export default Icon;
