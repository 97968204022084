import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { paths, links, colors, gs } from '../../constants';
import { useSelector } from 'react-redux';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  IconButton,
  useBoolean,
  RadioGroup,
  Radio,
  HStack,
  VStack,
  Box,
  Text,
} from '@chakra-ui/react';
import { MyInput, MyTextarea } from './MyFields';
import Button from '../shared/Button';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { Input, Tab, TabList, Tabs, TabPanel, TabPanels } from '@chakra-ui/react';
import { Alert } from 'components/shared/Alert';
import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/high-res.css';

export const FormContainer = props => {
  const { form } = props;
  console.log({ form });
  return (
    <VStack spacing={7} alignItems="start" maxWidth="600px">
      {form.formError && <Alert error title={form.formError} />}
      {props.children}
    </VStack>
  );
};

export const FormField = props => {
  const { field, label, desc, children, helper, isRequired, errors } = props;

  // console.log({ errors });

  return (
    <FormControl isRequired={isRequired} isInvalid={errors}>
      {label && (
        <FormLabel htmlFor={field} fontSize="14px" fontWeight="bold" color="gray.600">
          {label}
        </FormLabel>
      )}
      {desc && (
        <Text fontSize="13px" color="gray.600" mb={3}>
          {desc}
        </Text>
      )}
      {children}
      {!errors && helper && (
        <FormHelperText textAlign="start" color="gray.600" fontSize="12px">
          {helper}
        </FormHelperText>
      )}
      {errors && errors.map((error, key) => <FormErrorMessage key={key}>{error}</FormErrorMessage>)}
    </FormControl>
  );
};

export const FormFieldInput = props => {
  return (
    <FormField {...props}>
      <MyInput type="text" {...props} />
    </FormField>
  );
};

export const FormFieldTextarea = props => {
  return (
    <FormField {...props}>
      <MyTextarea minH="96px" {...props} />
    </FormField>
  );
};

export const FormFieldPassword = props => {
  const [isShowing, showBool] = useBoolean();
  const EyeIcon = isShowing ? FaEye : FaEyeSlash;
  return (
    <FormField {...props}>
      <MyInput
        type={isShowing ? 'text' : 'password'}
        {...props}
        right={
          <IconButton
            variant="ghost"
            color="gray"
            icon={<EyeIcon fontSize="1.25rem" />}
            onClick={showBool.toggle}
            size="sm"
          />
        }
      />
    </FormField>
  );
};

export const FormFieldRadioGroup = ({ items, ...props }) => {
  return (
    <FormField {...props}>
      <RadioGroup {...props}>
        <HStack>
          {items.map((item, key) => (
            <Radio value={item.value} key={key}>
              {item.label}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
    </FormField>
  );
};

export const FormFieldPhone = props => {
  const countryCode = useSelector(state => state.local.ipData?.countryCode);

  console.log({ countryCode });

  return (
    <FormField {...props}>
      <PhoneInput
        country={countryCode?.toLowerCase() || 'us'}
        {...props}
        inputStyle={{
          width: '100%',
          backgroundColor: '#F7FAFC',
          height: '40px',
          fontSize: 15,
          // paddingLeft: '75px',
          borderRadius: '5px',
          borderColor: '#CBD5E0',
        }}
        buttonStyle={{
          backgroundColor: '#F7FAFC',
          // paddingLeft: '10px',
          // paddingRight: '10px',
          borderTopLeftRadius: '5px',
          borderBottomLeftRadius: '5px',
          borderColor: '#CBD5E0',
        }}
        dropdownStyle={{}}
      />
    </FormField>
  );
};
