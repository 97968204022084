import { lib, api, redux } from '@tlsocial/common';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const apiFetch = async path => {
  const url = `${lib.env.API_URL}${path}`;
  const response = await fetch(url);
  const result = await response.json();
  return result;
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const getDeepLink = link => {
  const DEEP_LINK_URL = 'https://link.socialtable.app/link/';
  const params = new URLSearchParams({
    link: link,
    apn: 'com.tlsocial.app',
    ibi: 'com.tlsocial.app',
    isi: '1619902829',
    efr: '1', // skip preview page
    // ifl: link,
    // afl: link,
  });

  return `${DEEP_LINK_URL}?${params.toString()}`;
};
