import { apiSlice } from './apiSlice';
import { updateFavLocationsRTK } from './rtkUpdates';

export const apiLocations = apiSlice.injectEndpoints({
  endpoints: build => ({
    addLocationFromSearch: build.mutation({
      query: body => ({ url: `/locations/add_from_search`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateFavLocationsRTK(data));
      },
    }),
    rateLocation: build.mutation({
      query: body => ({ url: `locations/${body.location_id}/rate`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateFavLocationsRTK(data));
      },
    }),
    getFeaturedLocations: build.query({ query: id => `/locations/featured/${id}` }),

    getLocation: build.query({ query: id => `/locations/${id}` }),
  }),
  overrideExisting: true,
});

export const {
  useGetFeaturedLocationsQuery,
  useGetLocationQuery,
  useAddLocationFromSearchMutation,
  useRateLocationMutation,
} = apiLocations;
