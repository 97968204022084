import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { paths, links, colors, gs } from '../../constants';
import {
  Tabs,
  TabList,
  Tab,
  Box,
  Flex,
  Button,
  Stack,
  MenuList,
  MenuItem,
  Menu,
  MenuButton,
  Center,
} from '@chakra-ui/react';

const MenuCount = props => {
  const { count } = props;
  return (
    <Center minW="22px" minH="22px" rounded="full" px={1} fontSize="13px" ml="6px" bg="gray.50" color="gray.600">
      {count}
    </Center>
  );
};

export const TabsMenu = props => {
  const { items, subpagesActive, ...extraProps } = props;
  const location = useLocation();
  const activeIndex = items.findIndex(
    item =>
      item.forceActive ||
      (subpagesActive && !item.activeExactOnly ? location.pathname.startsWith(item.to) : location.pathname === item.to),
  );

  return (
    <Tabs colorScheme="cyan" isManual index={activeIndex} {...extraProps}>
      {items.length > 0 && (
        <TabList>
          {items.map((item, key) => {
            const isActive = activeIndex === key;
            return (
              <Tab as={RouterLink} key={key} to={item.to} state={item.state}>
                <Flex height="40px" alignItems="center">
                  <Box fontWeight={isActive && 'bold'} fontSize="14px">
                    {item.label}
                  </Box>
                  {item.count > 0 && <MenuCount count={item.count} />}
                </Flex>
              </Tab>
            );
          })}
        </TabList>
      )}
    </Tabs>
  );
};

export const ButtonsMenu = props => {
  const { items, buttonProps, noShowActive, subpagesActive, ...extraProps } = props;
  const location = useLocation();
  return (
    <Stack direction="row" spacing={2} {...extraProps}>
      {items.map((option, key) => {
        const isActive =
          option.isActive ||
          (!noShowActive &&
            (subpagesActive && !option.activeExactOnly
              ? location.pathname.startsWith(option.to)
              : location.pathname === option.to));
        return (
          <Button
            as={option.to && RouterLink}
            key={key}
            variant="ghost"
            isActive={isActive}
            to={option.to}
            state={option.state}
            onClick={option.onClick}
            {...buttonProps}>
            {option.label}
          </Button>
        );
      })}
    </Stack>
  );
};

export const DropdownMenu = props => {
  const { items, OpenButton } = props;
  return (
    <Box>
      <Menu>
        <MenuButton cursor="pointer" as={Box}>
          {OpenButton}
        </MenuButton>
        <MenuList>
          {items.map((item, key) => {
            return (
              <MenuItem
                key={key}
                as={item.to && RouterLink}
                to={item.to}
                state={item.state}
                onClick={item.onClick}
                color="black">
                {item.label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </Box>
  );
};
