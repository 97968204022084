import React from 'react';
import * as utils from '../../utils';
import { lib, api, redux } from '@tlsocial/common';
import { H1, H2, H3 } from './Headings';
import { Box, HStack, Text, Circle, Stack } from '@chakra-ui/react';

const HowItWorksContainer = props => {
  const { items } = props;

  return (
    <Box>
      <H3 mb={7}>How it works</H3>
      <Stack spacing={4}>
        {items.map((item, index) => (
          <Box key={index}>
            <HStack align="flex-start">
              <Circle mr={3} size="40px" bg="gray.300" style={{ fontWeight: 'bold' }}>
                {index + 1}
              </Circle>

              <Box align="flex-start" maxW="700px">
                <Text mb={1} mt="8px" style={{ fontWeight: 'bold' }}>
                  {item.title}
                </Text>
                <Text>{item.description}</Text>
              </Box>
            </HStack>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default HowItWorksContainer;
