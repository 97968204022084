import { combineReducers } from 'redux';
import promise from 'redux-promise-middleware';
import formsSlice from './formsSlice';
import fireSlice from './fireSlice';
import deviceSlice from './deviceSlice';
import { localSlice } from './localSlice';
import relationsSlice from './relationsSlice';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import citySuggestedSlice from './citySuggestedSlice';
import cityLocalSlice from './cityLocalSlice';
import errorMiddleware from './errorMiddleware';
import { apiSlice } from '../api';
import { reduxStorage } from '../platformConfig';

const persistReducersOnClose = ['local', 'forms', 'suggested'];

const ignoreActions = [
  'local/setCurrentTime',
  'device/setActiveScreen',
  'api/subscriptions/unsubscribeQueryResult',
  'api/executeQuery/rejected',
];

const logAction = action => {
  // Log actions
  if (ignoreActions.includes(action.type)) {
    // Ignore
  } else if (action.type === 'api/queries/removeQueryResult') {
    console.log({ type: 'REMOVE', action });
  } else if (action.type === 'api/queries/queryResultPatched') {
    console.log({ type: `PATCH/${action.payload.queryCacheKey}`, action });
  } else if (action.type.startsWith('api/')) {
    const { endpointName, queryCacheKey, originalArgs } = action.meta?.arg || {};
    const type = queryCacheKey && originalArgs ? queryCacheKey : endpointName;
    console.log({ type: `api/${type}/${action.meta?.requestStatus}`, action });
  } else {
    console.log(action);
  }
};

const reducers = combineReducers({
  // persisted on app close
  local: localSlice.reducer,
  forms: formsSlice,
  cityLocal: cityLocalSlice, // Reset on city change

  // Restored from device / Firebase / API
  device: deviceSlice,
  fire: fireSlice,
  relations: relationsSlice,
  citySuggested: citySuggestedSlice, // Reset on city change

  // API
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducer = (state, action) => {
  logAction(action);

  // Reset state
  if (action.type === 'local/resetAllState') {
    // Exclude loggedOut reducer from being reset
    // state = persistReducersOnLogout.reduce((newState, keep) => {
    //   newState[keep] = state[keep];
    // }, {});
    state = {};
  }
  return reducers(state, action);
};

// Only persisting auth, screens, forms, and bools
const persistConfig = {
  key: 'root',
  storage: reduxStorage,
  stateReconciler: autoMergeLevel1,
  whitelist: persistReducersOnClose,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Override the variable we set in the outer scope
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
      .concat(apiSlice.middleware)
      .concat(errorMiddleware)
      .concat(promise);
  },
});

setupListeners(store.dispatch);
