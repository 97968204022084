import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { paths, links, colors, gs } from '../../constants';
import HomeScreen from '../home/HomeScreen';
import PageTemplate from './PageTemplate';
// import TestLoginScreen from '../testing/TestLoginScreen';
// import AboutTemplate from '../content/AboutTemplate';
import AboutScreen from '../content/AboutScreen';
import ContentTemplate from 'components/content/ContentTemplate';
import PrivacyScreen from 'components/content/PrivacyScreen';
import TermsScreen from 'components/content/TermsScreen';
import FaqScreen from 'components/content/FaqScreen';
import EtiquetteScreen from 'components/content/EtiquetteScreen';
import ApplyScreen from 'components/apply/ApplyScreen';
import CitiesScreen from 'components/cities/CitiesScreen';
import CityScreen from 'components/cities/CityScreen';
import CityTemplate from 'components/cities/CityTemplate';
import CityApplyScreen from 'components/cities/CityApplyScreen';
import InviteScreen from 'components/people/InviteScreen';
import PoolScreen from 'components/pools/PoolScreen';
import ProposalScreen from 'components/proposals/ProposalScreen';
import LocationScreen from 'components/locations/LocationScreen';

function AppRouter() {
  return (
    <Routes>
      {/* <Route element={<PageTemplateSimple />}>
        <Route path={paths.JOIN} element={<JoinScreen />} />
        <Route path={paths.LOGIN} element={<LoginScreen />} />
        <Route path={paths.RESET_PASSWORD} element={<ResetPasswordScreen />} />
        <Route path={paths.RESET_PASSWORD_CONFIRM} element={<ResetPasswordConfirmScreen />} />
        <Route path={paths.COMPLETE_TWITTER_LOGIN} element={<CompleteTwitterLoginScreen />} />
        <Route path={paths.STRIPE_PAYMENT_RESULT} element={<PaymentResultScreen />} />
      </Route> */}

      <Route path={paths.HOME} element={<PageTemplate />}>
        <Route index element={<HomeScreen />} />

        <Route element={<ContentTemplate />}>
          <Route path={paths.FAQ} element={<FaqScreen />} />
          <Route path={paths.ETIQUETTE} element={<EtiquetteScreen />} />
          <Route path={paths.ABOUT} element={<AboutScreen />} />
          <Route path={paths.PRIVACY} element={<PrivacyScreen />} />
          <Route path={paths.TERMS} element={<TermsScreen />} />
          <Route path={paths.JOIN} element={<ApplyScreen />} />
          <Route path={paths.CITIES} element={<CitiesScreen />} />
          <Route path={paths.INVITE} element={<InviteScreen />} />
        </Route>

        <Route path={paths.POOL} element={<PoolScreen />} />
        <Route path={paths.PROPOSAL} element={<ProposalScreen />} />
        <Route path={paths.LOCATION} element={<LocationScreen />} />

        <Route path={paths.CITY} element={<CityTemplate />}>
          <Route index element={<CityScreen />} />
          <Route path={paths.CITY_APPLY} element={<CityApplyScreen />} />
        </Route>

        {/* Testing */}
        {/* <Route path={paths.TEST_LOGIN} element={<TestLoginScreen />} /> */}
      </Route>
    </Routes>
  );
}

export default AppRouter;
