import { Box, Center, Flex, HStack, IconButton, Img, Stack, Text, useDisclosure } from '@chakra-ui/react';
import { MaxContainer } from 'components/shared/MaxContainer';
import { Modal } from 'components/shared/Modals';
import * as React from 'react';
import { FiChevronDown, FiHelpCircle, FiMenu, FiSearch, FiSettings, FiX } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useIsFullWidth, useModal, useUser } from '../../hooks';
import { ButtonsMenu, DropdownMenu } from 'components/shared/Menus';
import { Link, LinkArea } from 'components/shared/Links';
import Button from 'components/shared/Button';
import { paths } from '../../constants';
import { useTranslation } from 'react-i18next';

const MainHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktop = useIsFullWidth('md');
  const { t } = useTranslation();

  const menuButtonProps = {
    _hover: {
      bg: 'blackAlpha.200',
    },
    _active: {
      bg: 'blackAlpha.300',
    },
    color: 'black',
  };

  const linksNoAuth = [
    { label: 'Cities', to: paths.CITIES },
    { label: t('links.faq'), to: paths.FAQ },
    { label: t('links.etiquette'), to: paths.ETIQUETTE },
    // { label: t('links.about'), to: paths.ABOUT },
  ];

  const rightLinks = [{ label: 'Join', to: paths.JOIN }];

  const renderRightMenu = () => {
    if (isDesktop) {
      return <ButtonsMenu items={rightLinks} subpagesActive buttonProps={menuButtonProps} />;
      // return (
      //   <Box position="relative">
      //     <Text style={{ marginRight: 110, fontWeight: 'bold', fontSize: 16, color: 'white' }}>Download the app!</Text>
      //     <Box position="absolute" style={{ right: 0, top: -10, backgroundColor: 'white' }}>
      //       <Img src="/assets/img/qr-code.png" h="100px" w="100px" />
      //     </Box>
      //   </Box>
      // );
    }
  };

  return (
    <Box boxShadow="sm" bgGradient="linear(to-r, light, light)" color="white">
      <MaxContainer py={{ base: 4, md: 4 }} boxShadow="sm">
        <Flex justify="space-between" minH="60px" alignItems="center">
          <HStack spacing="4">
            <Link
              to={paths.HOME}
              px={3}
              borderRadius={'md'}
              align="center"
              mr={2}
              ml={-3}
              pb={0}
              pt={1}
              _hover={{
                bg: 'blackAlpha.200',
              }}>
              <Text>
                <Text
                  as="span"
                  fontSize={24}
                  color="primary.500"
                  fontFamily="Lato, sans-serif"
                  fontWeight="bold"
                  mb={-3}>
                  SOCIAL
                </Text>
                <Text
                  as="span"
                  fontSize={24}
                  color="primary.500"
                  fontFamily="Lato, sans-serif"
                  fontWeight="bold"
                  opacity={0.7}>
                  TABLE
                </Text>
              </Text>
            </Link>
            {isDesktop && <ButtonsMenu items={linksNoAuth} subpagesActive buttonProps={menuButtonProps} />}
          </HStack>

          <HStack spacing="5">
            {!isDesktop && (
              <DropdownMenu
                items={linksNoAuth}
                OpenButton={<IconButton icon={<FiMenu variant="ghost" fontSize="1.25rem" color="black" />} />}
              />
            )}
            {renderRightMenu()}
          </HStack>
        </Flex>
      </MaxContainer>
    </Box>
  );
};

export default MainHeader;
