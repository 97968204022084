import { Box, Container } from '@chakra-ui/react';
import React from 'react';

export const MaxContainer = props => {
  return <Container maxW="1100px" px={[4, 6, 8]} {...props} />;
};

export const ContentContainer = props => {
  return <Box py={8} {...props} />;
};

export const MaxContentContainer = props => {
  const { children, ...extraProps } = props;
  return (
    <Box {...extraProps}>
      <MaxContainer>
        <ContentContainer>{children}</ContentContainer>
      </MaxContainer>
    </Box>
  );
};
