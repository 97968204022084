import { updateAttendeeRTK } from './rtkUpdates';
import { tags } from './rtkTags';
import { arrayToObject, arrayToObjectValues } from '../library';
import { constructEventsFromAPI } from './apiConstruct';
import { apiSlice } from './apiSlice';

export const apiEvents = apiSlice.injectEndpoints({
  endpoints: build => ({
    getProfileEvents: build.query({
      query: profileId => `/events/profile/${profileId}/attended`,
      transformResponse: response => {
        return constructEventsFromAPI(response.events_data, response.relations);
      },
    }),
    getCurrentUserEvents: build.query({
      query: () => `/events/current_user`,
      providesTags: [tags.CURRENT_USER_EVENTS],
      transformResponse: response => {
        const events = constructEventsFromAPI(response.events_data, response.relations);
        return {
          d_events: arrayToObject(events, 'id'),
          d_chatEventId: arrayToObjectValues(events, 'chat_id', 'id'),
        };
      },
    }),
    rateExperience: build.mutation({
      query: body => ({ url: `events/${body.event_id}/rate_experience`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateAttendeeRTK(data));
      },
    }),
    rateExperienceUser: build.mutation({
      query: body => ({ url: `events/${body.event_id}/rate_experience_user`, method: 'PUT', body }),
    }),
    rateExperienceConnect: build.mutation({
      query: body => ({ url: `events/${body.event_id}/rate_experience_connect`, method: 'PUT', body }),
    }),
    cancelAttendance: build.mutation({
      query: body => ({ url: `events/${body.event_id}/cancel_attendance`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateAttendeeRTK(data));
      },
      invalidatesTags: [tags.CURRENT_USER_EVENTS],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetProfileEventsQuery,
  useGetCurrentUserEventsQuery,
  useRateExperienceMutation,
  useRateExperienceUserMutation,
  useRateExperienceConnectMutation,
  useCancelAttendanceMutation,
} = apiEvents;
