import { arrayToObject } from '../library';
import { constructProposalsFromAPI } from './apiConstruct';
import { tags } from './rtkTags';
import { apiSlice } from './apiSlice';

export const apiProposals = apiSlice.injectEndpoints({
  endpoints: build => ({
    getCityProposals: build.query({
      query: cityId => `/proposals/city/${cityId}`,
      transformResponse: response => arrayToObject(constructProposalsFromAPI(response), 'id'),
      providesTags: [tags.CITY_PROPOSALS],
    }),
    getProposal: build.query({
      query: id => `/proposals/${id}`,
      transformResponse: response => constructProposalsFromAPI(response)[0],
    }),
    getMyProposals: build.query({
      query: () => `/proposals/my_proposals`,
      transformResponse: response => arrayToObject(constructProposalsFromAPI(response), 'id'),
      providesTags: [tags.MY_PROPOSALS],
    }),
    editProposal: build.mutation({
      query: body => ({ url: `/proposals/edit`, method: 'POST', body }),
      invalidatesTags: [tags.MY_PROPOSALS, tags.CITY_PROPOSALS],
    }),
    buyTicketProposal: build.mutation({
      query: body => ({ url: `/proposals/${body.proposal_id}/buy_ticket`, method: 'PUT', body }),
      invalidatesTags: [tags.CITY_PROPOSALS],
    }),
    udpateProposalRegistration: build.mutation({
      query: body => ({ url: `/proposals/${body.proposal_id}/update_registration`, method: 'PUT', body }),
      invalidatesTags: [tags.CITY_PROPOSALS],
    }),
    cancelProposalRegistration: build.mutation({
      query: body => ({ url: `/proposals/${body.proposal_id}/cancel_registration`, method: 'PUT', body }),
      invalidatesTags: [tags.CITY_PROPOSALS],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCityProposalsQuery,
  useGetProposalQuery,
  useGetMyProposalsQuery,
  useEditProposalMutation,
  useBuyTicketProposalMutation,
  useUdpateProposalRegistrationMutation,
  useCancelProposalRegistrationMutation,
} = apiProposals;
