import { apiSlice } from './apiSlice';

export const apiAuth = apiSlice.injectEndpoints({
  endpoints: build => ({
    updateFirebaseToken: build.mutation({
      query: body => ({ url: `/auth/update_firebase_token`, method: 'PUT', body }),
    }),
    loginWithEmail: build.mutation({
      query: body => ({ url: `/auth/login_with_email`, method: 'PUT', body }),
    }),
    loginWithPhone: build.mutation({
      query: body => ({ url: `/auth/login_with_phone`, method: 'PUT', body }),
    }),
    testLoginAsNewUser: build.mutation({
      query: body => ({ url: `/auth/test_login_as_new_user`, method: 'PUT', body }),
    }),
    deleteAccount: build.mutation({
      query: body => ({ url: `/auth/delete_account`, method: 'PUT', body }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useDeleteAccountMutation,
  useLoginWithEmailMutation,
  useLoginWithPhoneMutation,
  useTestLoginAsNewUserMutation,
  useUpdateFirebaseTokenMutation,
} = apiAuth;
