import React from 'react';
import { useLocation } from 'react-router-dom';

export const useObjectOrFetch = (queryHook, queryParams, forceSkip) => {
  const location = useLocation();
  const routeObject = location.state?.object;

  // If we have an object in the route state, we don't need to fetch it
  const skip = forceSkip || Boolean(routeObject);
  const { currentData: fetchedObject, ...extra } = queryHook(queryParams, { skip });

  const object = fetchedObject || routeObject;

  return { object, ...extra };
};
