import { api } from '@tlsocial/common';

export const useUpcomingEvents = cityId => {
  const { data: pools, isLoading: isLoadingPools } = api.useGetCityPoolsQuery(cityId, { skip: !cityId });
  const { data: proposals, isLoading: isLoadingProposals } = api.useGetCityProposalsQuery(cityId, {
    skip: !cityId,
  });

  const proposalsArray = proposals && Object.values(proposals); // remove keys from shared query output

  const upcomingEvents =
    pools &&
    proposalsArray &&
    [...pools, ...proposalsArray].sort((a, b) => {
      return new Date(a.time_event) - new Date(b.time_event);
    });
  return { upcomingEvents, isLoadingEvents: isLoadingPools || isLoadingProposals };
};
