import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';
import { paths, links, colors, gs, basic } from '../../constants';
import { Box, Button as ChakraButton } from '@chakra-ui/react';

const Button = props => {
  const { secondary, warning, to, label, ...otherProps } = props;

  const variant = props.variant || secondary ? 'outline' : 'solid';

  return (
    <ChakraButton
      // textTransform={props.variant !== 'link' && 'uppercase'}
      as={to && RouterLink}
      to={to && !props.disabled && to}
      variant={variant}
      colorScheme={warning ? 'red' : 'primary'}
      px={5}
      fontWeight="black"
      {...otherProps}>
      <Box color={variant === 'solid' && 'white'}>{label || props.children}</Box>
    </ChakraButton>
  );
};

export const SelectButton = props => {
  const { label, labelActive, secondary, ...extraProps } = props;
  const variant = secondary ? 'outline' : props.isActive ? 'solid' : 'outline';
  const activeLabel = props.isActive ? labelActive : label;
  return <Button variant={variant} label={activeLabel} {...extraProps} />;
};

export const ButtonBox = props => {
  return <Button whiteSpace="normal" h="auto" w="full" py={3} variant="solid" {...props} />;
};

export default Button;
