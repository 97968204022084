import React from 'react';
import * as c from '../../constants/envConstants';
import * as utils from '../../utils/cityUtils';
import { Link } from 'react-router-dom';
import { lib, api, redux } from '@tlsocial/common';
import { Box, Image } from '@chakra-ui/react';
import { linksTo } from 'constants';

const CityItem = props => {
  const { city } = props;
  const source = city && lib.getCityImageUrl(city, 'large');

  return (
    <Box position="relative" bg="gray.100" h="200px" rounded={15} overflow="hidden">
      {city.has_image && <Image objectFit="cover" src={source} h="full" w="full" position="absolute" />}
      <Box style={styles.overlay} h="full" w="full" />
      <Box style={styles.nameContainer} h="full" w="full">
        {city.name}
      </Box>
    </Box>
  );
};

const styles = {
  overlay: {
    position: 'absolute',
    background: 'linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0))',
  },
  nameContainer: {
    position: 'absolute',
    display: 'flex',
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: 20,
    textShadow: '1px 1px 3px rgba(0,0,0, 0.9)',
  },
};

export default CityItem;
