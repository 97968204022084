import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { useUser } from '.';
import { useAnalytics } from '../api/analyticsApi';


export const useMain = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  useAnalytics();


  // Scroll to top on route change
  // TODO is this correct? seems like a standard thing everyone needs so not sure why I had to write this myself
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, behavior: 'instant' });
  }, [location.pathname]);

  // // GET Location data based on IP
  // useEffect(() => {
  //   fetch('http://ip-api.com/json')
  //     .then(response => response.json())
  //     .then(data => {
  //       dispatch(setAppValue({ key: 'ipData', value: data }));
  //     });
  // }, []);

  // useEffect(() => {
  //   if (cities && ipData) {
  //     const nearestDistance = 100;
  //     cities.forEach((city) => {
  //       const distance = utils.coord_distance(ipData.lat, ipData.lon, city.lat, city.lng);
  //       if (distance < nearestDistance) {
  //         setNearestCity(city);
  //       }
  //     });
  //   }
  // }, [cities, ipData]);
};
