import { api } from '@tlsocial/common';
import React from 'react';

const LocationDetail = ({ location }) => {
  const { data: { cuisine } = {}, isLoading: isLoadingAppData } = api.useGetAppDataWebQuery();
  if (cuisine) {
    const cuisineIds = JSON.parse(location.cuisine_json) || [];
    const activeCuisine = cuisineIds.map(cuisineId => cuisine[cuisineId]?.name);
    const cuisineLine = activeCuisine.join(', ');
    const dollarsText = location.price && '$'.repeat(location.price);
    if (dollarsText && cuisineLine) {
      return (
        <span>
          {dollarsText}
          <span> &nbsp; </span>
          {'•'}
          <span> &nbsp; </span>
          {cuisineLine}
        </span>
      );
    } else if (dollarsText) {
      return <span>{dollarsText}</span>;
    } else if (cuisineLine) {
      return <span>{cuisineLine}</span>;
    }
  }
  return null;
};

export default LocationDetail;
