import { constructPoolsFromAPI } from './apiConstruct';
import { tags } from './rtkTags';
import { apiSlice } from './apiSlice';

export const apiPools = apiSlice.injectEndpoints({
  endpoints: build => ({
    getCityPools: build.query({
      query: cityId => `/pools/city/${cityId}`,
      transformResponse: constructPoolsFromAPI,
      providesTags: [tags.POOLS],
    }),
    getPool: build.query({ query: id => `/pools/${id}` }),
    updatePoolRegistration: build.mutation({
      query: body => ({ url: `/pools/${body.pool_id}/update_registration`, method: 'PUT', body }),
      invalidatesTags: [tags.POOLS, tags.USER],
    }),
    buyTicketPool: build.mutation({
      query: body => ({ url: `/pools/${body.pool_id}/buy_ticket`, method: 'PUT', body }),
      invalidatesTags: [tags.POOLS],
    }),
    cancelPoolRegistration: build.mutation({
      query: body => ({ url: `/pools/${body.pool_id}/cancel_registration`, method: 'PUT', body }),
      invalidatesTags: [tags.POOLS, tags.USER],
    }),
    declinePairInvite: build.mutation({
      query: body => ({ url: `/pair_invites/${body.invite_id}/decline`, method: 'PUT', body }),
      invalidatesTags: [tags.POOLS],
    }),
    acceptPairInvite: build.mutation({
      query: body => ({ url: `/pair_invites/${body.invite_id}/accept`, method: 'PUT', body }),
      invalidatesTags: [tags.POOLS],
    }),
    cancelPairInvite: build.mutation({
      query: body => ({ url: `/pair_invites/${body.invite_id}/cancel`, method: 'PUT', body }),
      invalidatesTags: [tags.POOLS],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCityPoolsQuery,
  useGetPoolQuery,
  useUpdatePoolRegistrationMutation,
  useBuyTicketPoolMutation,
  useCancelPoolRegistrationMutation,
  useDeclinePairInviteMutation,
  useAcceptPairInviteMutation,
  useCancelPairInviteMutation,
} = apiPools;
