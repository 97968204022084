export const events = {
  route_view: 'route_view',
  twitter_login_start: 'twitter_login_start',
  twitter_login_success: 'twitter_login_success',
  email_login: 'email_login',
  signed_out: 'signed_out',
  click_choice: 'click_choice',
  click_button_group: 'click_button_group',
  click_button_multi: 'click_button_multi',
  slider_changed: 'slider_changed',
  click_checkbox: 'click_checkbox',
  submit_form: 'submit_form',
  purchase: 'purchase',

  error_fetch_axios: 'error_fetch_axios',
  error_fetch_custom: 'error_fetch_custom',
  error_fetch_invalid_token: 'error_fetch_invalid_token',
  error_django: 'error_django',
  error_not_authed: 'error_not_authed',
  error_timed_out: 'error_timed_out',
};
