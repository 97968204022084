export const REGISTRATION_HOUR_CLOSE_PLANNED = 20;
export const REGISTRATION_HOUR_CLOSE_DINNER = 15;
export const REGISTRATION_HOUR_CLOSE_HAPPY_HOUR = 15;
export const REGISTRATION_HOUR_CLOSE_LUNCH = 10;
export const REGISTRATION_HOUR_CLOSE_COFFEE = 13;

export const BASIC_PREFS = {
  NEVER: 1,
  OKAY: 2,
  PREFERRED: 3,
};

export const GENDER_MIX_CHOICES = {
  DONT_CARE: 0,
  PREFER_SAME: 1,
  REQUIRE_SAME: 2,
  PREFER_MIXED: 3,
  REQUIRE_MIXED: 4,
};

export const CHAT_TYPES = {
  DIRECT: 1,
  EVENT: 2,
  SUPPORT: 3,
};

export const BASE_LEVELS = {
  NONE: 0,
  LIKE: 1,
  LOVE: 2,
};

export const GROUP_LEVELS = {
  NONE: 0,
  LIKE: 1,
  LOVE: 2,
};

export const GOAL_TYPES = {
  PERSONAL: 1,
  BUSINESS: 2,
  CAREER: 3,
  BUSINESS_GROWTH: 4,
};

export const GOAL_LEVELS = {
  NO: 1,
  YES: 2,
  DEFINITELY: 3,
};

export const ADMIN_STATUS = {
  NONE: 1,
  PAUSED: 2,
  BANNED: 3,
  WAITLISTED: 4,
  APPROVED: 5,
};

export const USER_LEVEL = {
  NEW: 0,
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
};

export const MEET_RATING = {
  PENDING: 0,
  BLOCKED: 1,
  PASS: 2,
  MEET: 3,
  STAR: 4,
};

export const ATTENDEE_STATUS = {
  UNKNOWN: 1,
  CANCELLED_EARLY: 2,
  CANCELLED_ON_DAY: 3,
  FEEDBACK_ATTENDED: 4,
  FEEDBACK_NOT_THERE: 5,
  FEEDBACK_EVENT_CANCELLED: 6,
};

export const EVENT_TYPE = {
  DINNER: 1,
  HAPPY_HOUR: 2,
  LUNCH: 3,
  COFFEE: 4,
};

export const HOST_TYPE = {
  HOST_ONLY: 1,
  HOST_PLUS_ONE: 2,
  HOST_PLUS_TWO: 3,
};

export const PROPOSAL_STATUS = {
  PENDING: 1,
  PUBLISHED: 2,
  CANCELLED: 3,
  DELETED: 4,
};

export const PROPOSAL_LIKE = {
  NONE: 0,
  INTERESTED: 1,
};

export const GENDER_CHOICES = {
  MALE: 'M',
  FEMALE: 'F',
  OTHER: 'O',
};

export const PLUS_ONE_CHOICES = {
  FRIEND_MALE: 1,
  FRIEND_FEMALE: 2,
  PARTNER_MALE: 3,
  PARTNER_FEMALE: 4,
};

export const ADD_GROUP_CONTEXT = {
  NONE: 0,
  ONBOARDING_FEATURED: 1,
  ONBOARDING_SEARCH: 2,
  SEARCH: 3,
  POPULAR: 4,
  PROFILE: 5,
  EVENT: 6,
  MY_GROUPS: 7,
  PROFILE_LIST: 8,
  EVENT_LIST: 9,
  ONBOARDING_MORE: 10,
};

export const RATE_USER_CONTEXT = {
  SUGGESTED: 1,
  PROFILE: 2,
  PROFILE_ITEM: 3,
  SUGGESTED_UNDO: 4,
};

export const POOL_STATUS = {
  PENDING: 0,
  OPEN: 1,
  CLOSED: 2,
  FINISHED: 3,
};

export const MATCH_STATUS = {
  PENDING: 0,
  SUCCESS: 1,
  FAILED: 2,
};

export const REGISTRATION_STATUS = {
  NONE: 1,
  REGISTERED: 3,
  AWAITING_PAYMENT: 4,
};

export const REFRESH_TYPES = {
  USER: 1,
  CITY: 2,
  CHATS: 3,
  EVENTS: 4,
  POOLS: 5,
};

export const MESSAGES = {
  NOTI_TEST: 1,
  NOTI_REGISTRATION_OPEN: 2,
  NOTI_CONFIRM: 3,
  NOTI_RATE_POSSIBLE_MATCHES: 4,
  NOTI_MATCHING_FAIL: 5,
  NOTI_MATCHING_SUCCESS: 6,
  NOTI_RATE_EXPERIENCE: 7,
  NOTI_NEW_CONNECTION: 8,
  NOTI_ADMIN_INVITED: 9,
  NOTI_ADMIN_PAUSED: 10,
  NOTI_NEW_INVITES: 14,
  NOTI_GRANT_HOSTING_PRIVILEGE: 15,
  DATA_REFRESH: 16,
  DATA_TEST: 22,
  NOTI_ONBOARDED_INVITEE: 23,
  NOTI_PAIR_INVITE_SEND: 24,
  NOTI_PAIR_INVITE_ACCEPT: 25,
  NOTI_PAIR_INVITE_DECLINE: 26,
  NOTI_PAIR_INVITE_CANCEL: 27,
  NOTI_ONBOARDED_FRIEND: 28,
  NOTI_PAIR_INVITE_WITHDRAWN: 29,
  NOTI_NEW_CHAT: 30,
  NOTI_NEW_COMPLIMENTS: 31,
  NOTI_NEW_BELL: 32,
  NOTI_INVITED: 33,
  NOTI_REGISTRATION_REMINDER: 34,
  NOTI_CREDIT_EARNED: 35,
  NOTI_PAIR_INVITE_EXPIRED: 36,
};

export const PAIR_INVITE_STATUS = {
  PENDING: 1,
  ACCEPTED: 2,
  DECLINED: 3,
  CANCELLED: 4,
  WITHDRAWN: 5,
  EXPIRED: 6,
};

export const BELLS = {
  BELL_TEST: 0,
  BELL_INVITED_BY: 1,
  BELL_CONTACT_JOINED: 2,
  BELL_INVITEE_JOINED: 3,
  BELL_NEW_COMPLIMENTS: 4,
  BELL_APPROVAL_GRANTED: 5,
  BELL_INVITES_GRANTED: 6,
  BELL_HOSTING_GRANTED: 7,
  BELL_MATCHING_FAILED: 8,
  BELL_CREDIT_ON_JOIN: 9,
  BELL_CREDIT_EARNED: 10,
};

export const BELL_MODAL_TYPES = [
  // BELLS.BELL_TEST,
  BELLS.BELL_INVITED_BY,
  // BELLS.BELL_CONTACT_JOINED,
  BELLS.BELL_INVITEE_JOINED,
  BELLS.BELL_NEW_COMPLIMENTS,
  BELLS.BELL_APPROVAL_GRANTED,
  BELLS.BELL_INVITES_GRANTED,
  BELLS.BELL_HOSTING_GRANTED,
];

export const LANGUAGE_LEVELS = {
  LANG_0: 0,
  LANG_1: 1,
  LANG_2: 2,
  LANG_3: 3,
  LANG_4: 4,
  LANG_5: 5,
};

export const MUTUAL_SETTING = {
  INTRODUCE: 1,
  DISPLAY: 2,
  CHAT: 3,
  CHAT_STAR: 4,
};

export const INVITE_STATUS = {
  NONE: 0,
  SENT: 1,
  WITHDRAWN: 2,
  ACCEPTED: 3,
};
