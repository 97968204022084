import { createSlice } from '@reduxjs/toolkit';

let initialState = {
  livePoolStatus: {},
};

const cityLocalSlice = createSlice({
  name: 'cityLocal',
  initialState,
  reducers: {
    resetcityLocalState: () => initialState,
    setLivePoolStatus: (state, action) => {
      const payload = action.payload;
      state.livePoolStatus[payload.poolId] = payload.status;
    },
  },
});

// Action creators are generated for each case reducer function
export const { resetcityLocalState, setLivePoolStatus } = cityLocalSlice.actions;

export default cityLocalSlice.reducer;
