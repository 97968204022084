import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  messages: {}, // firebaseChatId => messages
  unseen: {}, // firebaseChatId => unseen count
};

const fireSlice = createSlice({
  name: 'fire',
  initialState,
  reducers: {
    clearLocalChatData: () => initialState,
    setChatMessages: (state, action) => {
      const updates = action.payload;
      let newMessages = { ...state.messages };
      let newUnseen = { ...state.unseen };
      updates.forEach(update => {
        const { addToUnread, message } = update;
        newMessages[message.firebaseChatId] = [message, ...(newMessages[message.firebaseChatId] || [])];
        if (addToUnread) {
          newUnseen[message.firebaseChatId] = (newUnseen[message.firebaseChatId] || 0) + 1;
        }
      });
      state.messages = newMessages;
      state.unseen = newUnseen;
    },
    addUnseenCounts: (state, action) => {
      const newUnseenCounts = action.payload;
      state.unseen = { ...state.unseen, ...newUnseenCounts };
    },
    removeUnseenCounts: (state, action) => {
      const fChatIds = action.payload;
      fChatIds.forEach(fChatId => {
        delete state.unseen[fChatId];
      });
    },
    chatSendMessage: (state, action) => {
      // message already exists locally.. updating sent status to true.
      const { firebaseChatId, messageId } = action.payload;
      const messages = state.messages[firebaseChatId];
      const index = messages.findIndex(message => message.id === messageId);
      const newMessages = messages.map((item, i) => {
        return i === index ? { ...item, sent: true } : item;
      });
      // console.log(newMessages);
      state.messages[firebaseChatId] = newMessages;
    },
    chatMarkAsSeen: (state, action) => {
      const firebaseChatId = action.payload;
      state.unseen[firebaseChatId] = 0;
    },
  },
});

export const {
  clearLocalChatData,
  setChatMessages,
  addUnseenCounts,
  removeUnseenCounts,
  chatSendMessage,
  chatMarkAsSeen,
} = fireSlice.actions;

export default fireSlice.reducer;

// export default (state = initialState, action) => {
//   const base_action = action.type.replace(FULFILLED, '');
//   const payload = action.payload;
//   switch (base_action) {
//     case actions.RESET_CHATS:
//       return initialState;
//     case actions.SET_CHAT_MESSAGES: {
//       // console.log({action});
//       const updates = payload;
//       let newMessages = { ...state.messages };
//       let newUnseen = { ...state.unseen };
//       updates.forEach(update => {
//         const { addToUnread, message } = update;
//         newMessages[message.firebaseChatId] = [message, ...(newMessages[message.firebaseChatId] || [])];
//         if (addToUnread) {
//           newUnseen[message.firebaseChatId] = (newUnseen[message.firebaseChatId] || 0) + 1;
//         }
//       });
//       return {
//         ...state,
//         messages: newMessages,
//         unseen: newUnseen,
//       };
//     }
//     case actions.SET_CHAT_UNSEEN_COUNTS: {
//       // console.log({action});
//       return {
//         ...state,
//         unseen: {
//           ...state.unseen,
//           ...payload,
//         },
//       };
//     }
//     case actions.CHAT_SEND_MESSAGE: {
//       // console.log({ action });
//       // message already exists locally.. updating sent status to true.
//       // console.log(payload);
//       const { firebaseChatId, messageId } = payload;
//       const messages = state.messages[firebaseChatId];
//       const index = messages.findIndex(message => message.id === messageId);
//       const newMessages = messages.map((item, i) => {
//         return i === index ? { ...item, sent: true } : item;
//       });
//       // console.log(newMessages);
//       return {
//         ...state,
//         messages: {
//           ...state.messages,
//           [firebaseChatId]: newMessages,
//         },
//       };
//     }
//     case actions.CHAT_MARK_AS_SEEN + '_PENDING': {
//       // console.log({action});
//       const firebaseChatId = payload;
//       return {
//         ...state,
//         unseen: {
//           ...state.unseen,
//           [firebaseChatId]: 0,
//         },
//       };
//     }
//     default:
//       return state;
//   }
// };
