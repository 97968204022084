import React from 'react';
import * as c from '../../constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import Skeleton from 'react-loading-skeleton';
import { Box, HStack, Image, Stack, Text, Flex } from '@chakra-ui/react';
import { MagicLink } from 'components/shared/Links';
import { linksTo } from 'constants';
import * as utils from '../../utils';
import Avatar from 'components/people/Avatar';
import { format, formatDistance } from 'date-fns';

const CityUpdateItem = props => {
  const { update } = props;

  const renderImage = () => {
    if (update?.has_image) {
      const uri = lib.getUpdateImageUrl(update);
      return <Image objectFit="cover" src={uri} style={styles.image} />;
    }
  };

  const byText = update.profile && `${update.profile.full_name} — `;
  return (
    <Box>
      <Text style={styles.title}>{update.title}</Text>

      <Flex py={2} align="center">
        {update.profile && <Avatar size={25} profile={update.profile} />}
        <Text style={styles.timeAgo}>
          {byText}
          {formatDistance(new Date(update.time_posted), new Date())} ago
        </Text>
      </Flex>

      <Box>
        {renderImage()}
        <Text style={styles.body}>{update.body}</Text>
      </Box>
    </Box>
  );
};

const styles = {
  title: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  body: {
    marginTop: 15,
  },
  timeAgo: {
    paddingLeft: 5,
    opacity: 0.5,
    fontSize: 13,
  },
  image: {
    height: 200,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 10,
  },
};

export default CityUpdateItem;
