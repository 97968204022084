import React from 'react';
import { Provider } from 'react-redux';
import { redux } from '@tlsocial/common';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { BrowserRouter } from 'react-router-dom';
import AppMain from './AppMain';
import Bugsnag from '@bugsnag/js';
import ErrorScreen from './ErrorScreen';
import { ChakraProvider } from '@chakra-ui/react';
import { HelmetProvider } from 'react-helmet-async';
import chakraTheme from '../../theme';
import 'focus-visible/dist/focus-visible';
import { ModalProvider } from './ModalContext';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import * as c from '../../constants/envConstants';

Bugsnag.start({
  apiKey: c.BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()],
  // appVersion: version,
  enabledReleaseStages: ['production', 'staging'],
});

const store = redux.store;

let persistor = persistStore(store);

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

function App() {
  return (
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <BrowserRouter>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ChakraProvider theme={chakraTheme}>
                <HelmetProvider>
                  <ModalProvider>
                    <AppMain />
                  </ModalProvider>
                </HelmetProvider>
              </ChakraProvider>
            </PersistGate>
          </Provider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

export default App;
