export let reduxStorage;
export let toastError;
export let bugsnagNotify;
export let bugsnagSetUser;
export let platformImages;

export const initPlatform = params => {
  console.log('initPlatform', params);
  reduxStorage = params.reduxStorage;
  toastError = params.toastError;
  bugsnagNotify = params.bugsnagNotify;
  bugsnagSetUser = params.bugsnagSetUser;
  platformImages = params.platformImages;
};
