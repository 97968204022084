import React from 'react';
import MyHelmet from 'components/shared/MyHelmet';
import { H2, H3 } from 'components/shared/Headings';
import { Box, Text } from '@chakra-ui/react';

const IMPACT_CONTENT = [
  {
    title: 'Genuine real-life connection in a digital world',
    body: 'In a world with so much time spent behind screens, many people feel increasing isolation and loneliness. And it turns out all those likes on your latest Instagram photo is not the solution. People crave genuine connection in the real world. And that’s exactly what SocialTable provides.',
  },
  {
    title: 'Empathy, not division',
    body: 'Social media is amplifying content that outrages and divides us. People with differing views are being treated as not just wrong, but actually bad people. An appreciation of our shared humanity is being lost through the screen. The antidote is stepping outside to connect with real people in real life! SocialTable provides an environment conducive to connection, empathy, and understanding.',
  },
  {
    title: 'New ideas, inspirations, and opportunities',
    body: "SocialTable offers a chance to interact with people from different backgrounds and perspectives. It's a chance to be inspired and break out of your bubble. It’s the perfect environment for new ideas to sprout through lively conversation. You might just encounter a person or idea that changes the direction of your life! It’s serendipity by design.",
  },
];

const AboutScreen = props => {
  const renderSection = (item, key) => {
    return (
      <Box key={key} mb={5}>
        <H3 mb={2}>{item.title}</H3>
        <Text color="gray.700">{item.body}</Text>
      </Box>
    );
  };

  return (
    <>
      <div style={{ maxWidth: 700 }}>
        <MyHelmet show title="About" />
        {/* <H2 mb={5}>Social good</H2> */}
        {IMPACT_CONTENT.map(renderSection)}
      </div>
    </>
  );
};

export default AboutScreen;
