import { Box, Flex } from '@chakra-ui/react';
import { H1 } from 'components/shared/Headings';
import { MaxContainer, MaxContentContainer } from 'components/shared/MaxContainer';
import MyHelmet from 'components/shared/MyHelmet';
import React from 'react';
import { Outlet } from 'react-router';
import { useParams } from 'react-router-dom';
import { lib, api, redux } from '@tlsocial/common';
import * as c from '../../constants/envConstants';
import { Link } from 'components/shared/Links';
import { paths, linksTo } from 'constants';
import * as utils from '../../utils';

const CityTemplate = props => {
  const { cityKey } = useParams();
  const { data: cities, isLoading } = api.useGetActiveCitiesQuery();
  const city = cities && cities.find(city => city.city_key === cityKey);
  const source = city && lib.getCityImageSource(city, 'xlarge');
  const imageUrl = { backgroundImage: `url(${source})` };

  const renderHeader = () => {
    return (
      <Box h="350px" bg="gray.200" color="white" style={{ ...styles.headerOuterContainer, ...imageUrl }}>
        <Flex h="full" alignItems="flex-end" bgGradient="linear(to-b, transparent 50%, blackAlpha.700 100%)">
          <MaxContainer w="full">
            {city && (
              <H1 mb={8} textShadow="1px 1px 5px rgba(0,0,0,1)">
                <Link {...linksTo.CITY(city)} color="white">
                  {city?.name}
                </Link>
              </H1>
            )}
          </MaxContainer>
        </Flex>
      </Box>
    );
  };

  return (
    <>
      <MyHelmet title={`SocialTable ${city?.name} - Curated dinners with interesting people`} />
      {renderHeader()}
      <MaxContentContainer pb={10} bg="light">
        <Outlet />
      </MaxContentContainer>
    </>
  );
};

const styles = {
  headerOuterContainer: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  },
};

export default CityTemplate;
