import React from 'react';
import * as c from '../../constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import { getSvgPath } from 'figma-squircle';
import { Box } from '@chakra-ui/react';

const SQUIRCLE_CORNER_SMOOTHING = 0.8;

const Squircle = props => {
  const { children, width, height, cornerRadius, ...extra } = props;

  const svgPath = getSvgPath({
    width,
    height,
    cornerRadius,
    cornerSmoothing: SQUIRCLE_CORNER_SMOOTHING,
  });

  return (
    <Box {...extra}>
      <div style={{ width, height, clipPath: `path('${svgPath}')` }}>{props.children}</div>
    </Box>
  );
};

export default Squircle;
