import { Box, LinkBox, LinkOverlay, Link as ChakraLink, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { basic, colors, gs } from '../../constants';

export const Link = props => {
  if (props.href) {
    return <ChakraLink color="primary.600" {...props} />;
  } else if (props.to) {
    return <ChakraLink as={RouterLink} color="primary.600" {...props} />;
  } else {
    return <Text {...props} />;
  }
};

export const MagicLink = props => {
  const { hoverColor, ...extraProps } = props;
  if (props.to) {
    return <LinkArea {...props} />;
  } else if (props.onClick) {
    return <HoverArea {...props} />;
  } else if (props.href) {
    return <Link {...props} />;
  } else {
    return <Box {...extraProps} />;
  }
};

export const LinkInner = props => {
  const { to, state } = props;
  return <LinkOverlay as={RouterLink} to={to} state={state}></LinkOverlay>;
};

export const LinkArea = props => {
  const { to, state, hoverColor, skipLinkInner, ...extraProps } = props;
  const hoverStyle = { backgroundColor: hoverColor || 'gray.50' };
  return (
    <LinkBox _hover={hoverStyle} {...extraProps}>
      {!skipLinkInner && <LinkInner to={to} state={state} />}
      {props.children}
    </LinkBox>
  );
};

export const HoverArea = props => {
  const { hoverColor, ...extraProps } = props;
  const hoverStyle = { backgroundColor: hoverColor || 'gray.50' };
  return (
    <Box cursor="pointer" _hover={hoverStyle} {...extraProps}>
      {props.children}
    </Box>
  );
};
