import { useTranslation } from 'react-i18next';

const noDecimalCountries = [
  'BIF',
  'CLP',
  'DJF',
  'GNF',
  'JPY',
  'KMF',
  'KRW',
  'MGA',
  'PYG',
  'RWF',
  'UGX',
  'VND',
  'VUV',
  'XAF',
  'XOF',
  'XPF',
];

const getDecimalAmount = (amount, currency) => {
  if (noDecimalCountries.includes(currency)) {
    return amount;
  } else {
    return amount / 100;
  }
};

export const useFormatPrice = () => {
  const { t } = useTranslation();
  return (amount, currency) => {
    const decimalAmount = getDecimalAmount(amount, currency);

    const minimumFractionDigits = 0;
    const maximumFractionDigits = Number.isInteger(amount) ? 0 : 2;
    return t(`{{amount, currency, minimumFractionDigits, maximumFractionDigits}}`, {
      amount: decimalAmount,
      currency,
      minimumFractionDigits,
      maximumFractionDigits,
    });
  };
};
