import { updateCardsRTK, updateUserRTK } from './rtkUpdates';
import { apiSlice } from './apiSlice';

export const apiPayment = apiSlice.injectEndpoints({
  endpoints: build => ({
    addCreditCard: build.mutation({
      query: body => ({ url: `/cards/add`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateUserRTK(data.user));
        dispatch(updateCardsRTK(data.payment_methods));
      },
    }),
    deleteCreditCard: build.mutation({
      query: body => ({ url: `/cards/${body.card_id}/delete`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateUserRTK(data.user));
        dispatch(updateCardsRTK(data.payment_methods));
      },
    }),
    setPrimaryCreditCard: build.mutation({
      query: body => ({ url: `/cards/${body.card_id}/set_primary`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateUserRTK(data.user));
        dispatch(updateCardsRTK(data.payment_methods));
      },
    }),
    stripeStartSaveCard: build.mutation({
      query: body => ({ url: `/stripe/start_save_card`, method: 'PUT', body }),
    }),
    stripeStartPayment: build.mutation({
      query: body => ({ url: `/stripe/start_payment`, method: 'PUT', body }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useAddCreditCardMutation,
  useDeleteCreditCardMutation,
  useSetPrimaryCreditCardMutation,
  useStripeStartSaveCardMutation,
  useStripeStartPaymentMutation,
} = apiPayment;
