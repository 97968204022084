import React from 'react';
import AppRouter from './AppRouter';
import { useMain } from '../../hooks/useMain';
import MyHelmet from 'components/shared/MyHelmet';
import { createStandaloneToast } from '@chakra-ui/react';
import chakraTheme from '../../theme';

const { ToastContainer, toast } = createStandaloneToast({ theme: chakraTheme });

function AppMain() {
  useMain();

  return (
    <>
      <MyHelmet title="SocialTable" description="Curated Dinners with Interesting People" />
      <AppRouter />
      <ToastContainer />
    </>
  );
}

export default AppMain;
