import { createSlice } from '@reduxjs/toolkit';
import { apiApp } from '../api';
import { apiEvents } from '../api';
import { apiUsers } from '../api';

const initialState = {
  profileRelations: {},
  proposalLikes: {},
  optimisticProfile: {},
  optimisticProposal: {},
  optimisticLocation: {},
};

const setProfileRelation = (state, { payload }) => {
  console.log('setProfileRelation', { payload });
  const relation = payload.relation;
  const userToId = relation.user_to_id;
  state.profileRelations[userToId] = relation;
};

const setProfileOptimistic = (state, action) => {
  // console.log('setProfileOptimistic', { action });
  const { user_to_id, rating } = action.meta.arg.originalArgs;
  state.optimisticProfile[user_to_id] = rating;
};
const reverseProfileOptimistic = (state, action) => {
  const { user_to_id } = action.meta.arg.originalArgs;
  state.optimisticProfile[user_to_id] = null;
};

const relationsSlice = createSlice({
  name: 'relations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addMatcher(apiUsers.endpoints.rateUser.matchFulfilled, setProfileRelation)
      .addMatcher(apiEvents.endpoints.rateExperienceUser.matchFulfilled, setProfileRelation)
      // .addCase(rateProposal.fulfilled, (state, { payload }) => {
      //   if (payload.proposal_like) {
      //     const like = payload.proposal_like;
      //     const id = like.proposal_id;
      //     state.proposalLikes[id] = like;
      //   }
      // });
      .addMatcher(apiUsers.endpoints.rateUser.matchPending, setProfileOptimistic)
      .addMatcher(apiUsers.endpoints.rateUser.matchRejected, reverseProfileOptimistic)
      .addMatcher(apiEvents.endpoints.rateExperienceUser.matchPending, setProfileOptimistic)
      .addMatcher(apiEvents.endpoints.rateExperienceUser.matchRejected, reverseProfileOptimistic)
      .addMatcher(apiApp.endpoints.rateLocation.matchPending, (state, action) => {
        const { location_id, is_favorite } = action.meta.arg.originalArgs;
        state.optimisticLocation[location_id] = is_favorite;
      })
      .addMatcher(apiApp.endpoints.rateLocation.matchRejected, (state, action) => {
        const { location_id } = action.meta.arg.originalArgs;
        state.optimisticLocation[location_id] = null;
      });
    // .addMatcher(rtkSlice.endpoints.rateProposal.matchPending, (state, action) => {
    //   const { proposal_id, level } = action.meta.arg.originalArgs;
    //   state.optimisticProposal[arg.proposal_id] = payload.level;
    // })
    // .addMatcher(rtkSlice.endpoints.rateProposal.matchRejected, (state, action) => {
    //   const { proposal_id } = action.meta.arg.originalArgs;
    //   state.optimisticProposal[arg.proposal_id] = null;
    // })
  },
});

export default relationsSlice.reducer;
