import React from 'react';
import {
  Box,
  Center,
  Flex,
  GridItem,
  HStack,
  Img,
  OrderedList,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { MaxContainer, MaxContentContainer } from '../shared/MaxContainer';
import { H1, H2, H3 } from '../shared/Headings';
import { faCalendar } from '@fortawesome/pro-light-svg-icons/faCalendar';
import { faCocktail } from '@fortawesome/pro-light-svg-icons/faCocktail';
import { faFlag } from '@fortawesome/pro-light-svg-icons/faFlag';
import { faHandshake } from '@fortawesome/pro-light-svg-icons/faHandshake';
import { faLightbulb } from '@fortawesome/pro-light-svg-icons/faLightbulb';
import { faSmile } from '@fortawesome/pro-light-svg-icons/faSmile';
import { faUtensils } from '@fortawesome/pro-light-svg-icons/faUtensils';
import { MdSettings } from 'react-icons/md';
import { useIsFullWidth, useUpcomingEvents } from 'hooks';
import Button from '../shared/Button';
import { paths } from 'constants';
import { useTranslation } from 'react-i18next';
import Icon from '../shared/Icon';
import Spinner from '../shared/Spinner';
import * as c from '../../constants';
import { lib, api, redux } from '@tlsocial/common';
import UpcomingEvents from 'components/shared/UpcomingEvents';
import LocationItem from 'components/locations/LocationItem';
import { linksTo } from '../../constants';

const WHY = [
  { icon: faUtensils, header: 'home.why.1.title', body: 'home.why.1.desc' },
  { icon: faSmile, header: 'home.why.2.title', body: 'home.why.2.desc' },
  { icon: faLightbulb, header: 'home.why.3.title', body: 'home.why.3.desc' },
];

const HOW = [
  { icon: faFlag, body: 'home.how.1.title' },
  { icon: faCalendar, body: 'home.how.2.title' },
  { icon: faUtensils, body: 'home.how.3.title' },
];

const THEMES = [
  {
    icon: faCocktail,
    header: 'English-Japanese language exchange',
    body: 'Make new friends, enjoy new restaurants, and have fun socializing!',
  },
  {
    icon: faHandshake,
    header: 'English-only social events',
    body: 'Make new international friends, enjoy new restaurants, and have fun socializing!',
  },
  // {
  //   icon: FaComments,
  //   header: 'In-depth Discussion',
  //   body: 'Discuss your favorite topics with people who share your passion.',
  // },
];

// const THEMES = [
//   {
//     icon: FaCocktail,
//     header: 'Social',
//     body: 'Make new friends, enjoy new restaurants, and have fun socializing!',
//   },
//   {
//     icon: FaHandshake,
//     header: 'Professional',
//     body: 'Network with peers, share ideas, and help one another.',
//   },
//   {
//     icon: FaComments,
//     header: 'In-depth Discussion',
//     body: 'Discuss your favorite topics with people who share your passion.',
//   },
// ];

const HomeScreen = props => {
  const { t } = useTranslation();
  const cityId = lib.env.IS_PRODUCTION ? 54 : 707;

  const { data: city, isLoading: isLoadingCityData } = api.useGetCityQuery(cityId, { skip: !cityId });

  const { upcomingEvents, isLoadingEvents } = useUpcomingEvents(cityId);
  const { data: locations = {}, isLoading: isLoadingLocations } = api.useGetFeaturedLocationsQuery(cityId, {
    skip: !cityId,
  });

  const renderWhy = (content, key) => {
    return (
      <Flex key={key}>
        <Icon fixedWidth icon={content.icon} style={{ fontSize: 40, marginRight: 20 }} />
        <Box>
          <H2 mb={3}>{t(content.header)}</H2>
          <Text>{t(content.body)}</Text>
        </Box>
      </Flex>
    );
  };

  const renderHow = (content, key) => {
    return (
      <Box key={key}>
        <li>
          <p style={{ opacity: 0.8, fontSize: 18 }}>{t(content.body)}</p>
        </li>
      </Box>
    );
  };

  const renderTheme = (content, key) => {
    return (
      <Box h="100%" p={5} textAlign="center" rounded="lg" bg="rgba(255,255,255,0.3)" key={key}>
        <Icon icon={content.icon} color="primary.400" w="50px" h="50px" mb={1} />
        <H3 mb={2}>{content.header}</H3>
        <Text>{content.body}</Text>
      </Box>
    );
  };

  return (
    <>
      <Box style={styles.headerOuterContainer}>
        <Box style={styles.headerGradient}>
          <MaxContentContainer>
            <Box py={{ base: '50px', md: '70px' }} justify="center">
              {/* <H1 fontSize="44px">Curated Dinners with Interesting People</H1> */}
              <H1 fontSize="46px" maxW="700px">
                {/* {t('home.title')} */}
                {/* Curated dinners for Canggu coaches */}
                Build meaningful connections through curated dinners
                {/* Join our invite-only dinner club for Tokyo creators, influencers, and entrepreneurs */}
                {/* An invite-only dinner club for Tokyo creators, influencers, and entrepreneurs */}
              </H1>
              <Text maxW="700px" fontSize="17">
                SocialTable curates 4-8 person group dinners that bring like-minded people together for a memorable
                night of delicious food and engaging conversation. We aim to foster genuine connections and lasting
                friendships.
              </Text>

              <Button size="lg" label={'Join the Community'} mt={8} minW="200px" to={paths.JOIN} />

              {/* <HStack mt={8} justify="center">
                <a href="https://apps.apple.com/us/app/socialtable/id1619902829">
                  <Img src="/assets/img/app-apple2.png" h="50px" alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.tlsocial.app">
                  <Img src="/assets/img/app-android2.png" h="50px" alt="" />
                </a>
              </HStack> */}
            </Box>
          </MaxContentContainer>
        </Box>
      </Box>

      {city && (isLoadingEvents || upcomingEvents?.length > 0) && (
        <MaxContentContainer bg="light" py={5}>
          <HStack alignItems="center" mb={7} spacing={5}>
            <H2>Upcoming events {city && `in ${city?.name}`}</H2>
            <Button variant="ghost" label="More cities" to={paths.CITIES} />
          </HStack>
          {isLoadingEvents ? <Spinner /> : <UpcomingEvents items={upcomingEvents} />}
        </MaxContentContainer>
      )}

      {city && locations.length > 0 && (
        <MaxContentContainer py={5} bg="#eae8e8">
          <HStack alignItems="center" mb={7} spacing={5}>
            <H2>{city.name} locations</H2>
            <Button variant="ghost" label="More locations" {...linksTo.CITY(city)} />
          </HStack>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 3, lg: 3 }} spacing={6}>
            {locations.slice(0, 6).map((location, i) => (
              <GridItem key={location.id}>
                <LocationItem location={location} />
              </GridItem>
            ))}
          </SimpleGrid>
        </MaxContentContainer>
      )}

      <MaxContentContainer py={5}>
        <SimpleGrid columns={[1, 1, 2]} spacing={10}>
          <Center>
            <Img src="/assets/img/homescreen.jpg" maxH="700px" />
          </Center>
          <VStack spacing={10} justifyContent="center">
            {WHY.map(renderWhy)}
          </VStack>
        </SimpleGrid>
      </MaxContentContainer>

      <MaxContentContainer bg="light" py={5} align="center">
        <H2 mb={7} textAlign="center">
          {t('home.how.title')}
        </H2>
        <Box maxW="500px" textAlign="start">
          <OrderedList>{HOW.map(renderHow)}</OrderedList>
        </Box>
      </MaxContentContainer>

      {/* <MaxContentContainer bg="primary.200" py={5}>
        <H2 textAlign="center" mb={10}>
          Choose your event type
        </H2>
        <Stack direction={{ base: 'column', md: 'row' }} spacing={5}>
          {THEMES.map(renderTheme)}
        </Stack>
      </MaxContentContainer> */}

      {/* {isDesktop && (
        <MaxContentContainer bg="gray.100" py={5}>
          <Center>
            <Flex alignItems="center">
              <Img src="/assets/img/qr-code.png" height="100" width="100" mr={4} />
              <Box>
                <H2>Download SocialTable</H2>
                Scan to download the app
              </Box>
            </Flex>
          </Center>
        </MaxContentContainer>
      )} */}
    </>
  );
};

const styles = {
  headerOuterContainer: {
    backgroundColor: '#000000',
    backgroundImage: "url('/assets/img/above.jpeg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',
    borderRadius: 0,
    margin: 0,
  },
  headerGradient: {
    background: 'linear-gradient(90deg, rgba(0,0,0,0.75), rgba(0,0,0,0.4))',
  },
};

export default HomeScreen;
