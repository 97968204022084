import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ModalContext, ModalConfirmContext } from 'components/main/ModalContext';
import { useAsyncFunc } from './useAsync';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import AppRouter from 'components/main/AppRouter';
import { useBreakpointValue } from '@chakra-ui/react';
import { MODALS } from '../constants';

// This one doesn't trigger re-renders because of useMemo
export const useModal = () => {
  return useContext(ModalContext);
};

// This one triggers re-renders because of no useMemo (we need confirmLoading)
export const useModalConfirm = () => {
  return useContext(ModalConfirmContext);
};

export const useConfirmModal = (config, asyncFunc) => {
  const [funcProps, setFuncProps] = useState(null);
  const { isLoading, run } = useAsyncFunc(asyncFunc);
  const modal = useModalConfirm();

  // Detect confirm pressed
  useEffect(() => {
    if (modal.confirmLoading) {
      run(funcProps);
    }
  }, [modal.confirmLoading]);

  // Detect if modal should be closed
  useEffect(() => {
    if (!isLoading && modal.confirmLoading) {
      modal.close();
    }
  }, [isLoading, modal.confirmLoading]);

  const initiate = props => {
    setFuncProps(props);
    modal.open(MODALS.CONFIRM, config);
  };

  return initiate;
};
