import React from 'react';
import { lib, api, redux } from '@tlsocial/common';
import MyHelmet from 'components/shared/MyHelmet';
import { Box, Text } from '@chakra-ui/react';
import { H3 } from 'components/shared/Headings';
import { useTranslation } from 'react-i18next';

const EtiquetteScreen = props => {
  const { t } = useTranslation();

  const ETIQUETTE_CONTENT = [
    { title: t('etiquette.feels_included.title'), body: t('etiquette.feels_included.body') },
    { title: t('etiquette.dont_monopolize.title'), body: t('etiquette.dont_monopolize.body') },
    { title: t('etiquette.be_present.title'), body: t('etiquette.be_present.body') },
    { title: t('etiquette.good_intentions.title'), body: t('etiquette.good_intentions.body') },
  ];

  const renderSection = (item, key) => {
    return (
      <Box key={key} mb={7}>
        <H3 mb={2}>{item.title}</H3>
        <Text color="gray.700">{item.body}</Text>
      </Box>
    );
  };

  return (
    <>
      <MyHelmet show title={t('etiquette.title')} />
      <Box maxW="700px">{ETIQUETTE_CONTENT.map(renderSection)}</Box>
    </>
  );
};

export default EtiquetteScreen;
