import React from 'react';
import * as c from '../../constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import LocationDetail from './LocationDetail';
import Skeleton from 'react-loading-skeleton';
import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { linksTo } from 'constants';
import { LinkArea } from 'components/shared/Links';
import Icon from 'components/shared/Icon';
import { faHeart } from '@fortawesome/pro-light-svg-icons/faHeart';

const LocationItem = props => {
  const { location } = props;
  const source = lib.getLocationImageSource(location);

  return (
    <LinkArea {...linksTo.LOCATION(location)} h="full" bg="white" rounded="10" overflow="hidden">
      <Box h="150" w="full" bg="gray.200">
        {location.has_image && <Image h="full" w="full" objectFit="cover" src={source} alt="" />}
      </Box>
      <Box p={4}>
        <Flex alignItems={'flex-start'}>
          <Box flex={1}>
            <Box fontSize={16} fontWeight="bold" mb={1}>
              {location.name}
            </Box>
            <Box fontSize={13}>
              <LocationDetail location={location} />
            </Box>
          </Box>
          <Flex mt="1px" alignItems={'center'}>
            {location.count_fav > 0 && (
              <Text fontSize={13} fontWeight="bold" mr={1}>
                {location.count_fav}
              </Text>
            )}
            <Icon icon={faHeart} />
          </Flex>
        </Flex>
        <Text mt={2} fontSize={15} color="blackAlpha.800" noOfLines={2}>
          {location.desc}
        </Text>
      </Box>
    </LinkArea>
  );
};

export default LocationItem;
