import { constructContactsDataFromAPI, constructInviteFromAPI } from './apiConstruct';
import { tags } from './rtkTags';
import { apiSlice } from './apiSlice';

export const apiInvites = apiSlice.injectEndpoints({
  endpoints: build => ({
    getContactsData: build.query({
      query: body => ({ url: `/invites/get_contacts_data`, method: 'PUT', body }),
      transformResponse: constructContactsDataFromAPI,
      providesTags: [tags.CONTACTS],
    }),
    getInvites: build.query({
      query: () => `/invites`,
      providesTags: [tags.MY_INVITES],
    }),
    getInviteForRecipient: build.query({
      query: code => `/invites/${code}`,
      transformResponse: constructInviteFromAPI,
    }),
    generateInvite: build.mutation({
      query: body => ({ url: `/invites/generate`, method: 'PUT', body }),
      invalidatesTags: [tags.MY_INVITES, tags.USER],
    }),
    cancelInvite: build.mutation({
      query: code => ({ url: `/invites/${code}/cancel`, method: 'PUT' }),
      invalidatesTags: [tags.MY_INVITES, tags.USER],
    }),
    openInvite: build.mutation({
      query: code => ({ url: `/invites/${code}/opened`, method: 'PUT' }),
    }),
    acceptInvite: build.mutation({
      query: code => ({ url: `/invites/${code}/accept`, method: 'PUT' }),
      invalidatesTags: [tags.USER],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetInvitesQuery,
  useGetContactsDataQuery,
  useGetInviteForRecipientQuery,
  useGenerateInviteMutation,
  useCancelInviteMutation,
  useOpenInviteMutation,
  useAcceptInviteMutation,
} = apiInvites;
