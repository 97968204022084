import React from 'react';
import MyHelmet from 'components/shared/MyHelmet';
import { Box, Img, Show, Stack, Text, useToken, VStack, Wrap } from '@chakra-ui/react';
import { H2, H3 } from 'components/shared/Headings';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { getDeepLink } from 'utils';
import * as c from '../../constants';

const QUESTIONS = [
  // {
  //   question: 'Why is SocialTable invite-only?',
  //   answer:
  //     "While some services are 'invite-only' merely to generate buzz. For us it's a critical aspect of curating outstanding events.",
  // },
  {
    question: 'If I apply, can I still be invited by a friend?',
    answer: "Certainly! With an invite from an existing member you'll be granted immediate access.",
  },
  // {
  //   question: 'How can I maximize my chances of an invite?',
  //   answer: "Complete your profile. We'll be able to get a better sense of who you are and what you're interested in.",
  // },
];

const ApplyContent = props => {
  const { t } = useTranslation();
  const darkAccent = useToken('colors', 'darkAccent');

  return (
    <Stack direction={{ base: 'column-reverse', md: 'row' }} spacing={5} align="flex-start">
      <Box flex={1}>
        <Box maxW="700px" mb={7}>
          <Text>
            At SocialTable, our priority is curating exceptional experiences for our members. Our goal is to connect
            you with interesting, engaging, and genuine individuals who share common interests. 
            
            Most members join via referral, but if you're a great fit for our community, we'd love for you to apply!

          </Text>

          {/* <Text mb={4}>
              Step into the exclusive SocialTable community, where engaging, fun-loving, and genuine individuals with
              shared interests come together. While membership is invite-only, you can apply directly below if you
              believe you'll fit in with our existing members.
            </Text>

            <Text>
              Embrace the opportunity to connect with like-minded people and delight in unforgettable, curated dining
              experiences tailored to your interests. Apply now and become part of the vibrant SocialTable experience!
            </Text> */}

          <H2 mb={5} mt={10}>
            Apply via the SocialTable app
          </H2>

          <Text>
            To apply, just download the SocialTable app and set up a profile.
          </Text>

          <VStack spacing={8} align="stretch" mt={7}>
            <Wrap>
              <a href="https://apps.apple.com/us/app/socialtable/id1619902829">
                <Img src="/assets/img/app-apple2.png" h="40px" alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.tlsocial.app">
                <Img src="/assets/img/app-android2.png" h="40px" alt="" />
              </a>
            </Wrap>
            <Show above="sm">
              <QRCode value={getDeepLink('https://socialtable.app/download')} size={200} fgColor={darkAccent} />
            </Show>
          </VStack>

          {/* <H2 mb={5} mt={10}>
            FAQ
          </H2>

          {QUESTIONS.map((q, ii) => (
            <Box key={ii} mb={7}>
              <H3 mb={2}>{q.question}</H3>
              <Text color="gray.700">{q.answer}</Text>
            </Box>
          ))} */}
        </Box>
      </Box>
    </Stack>
  );
};

export default ApplyContent;
