import React, { useState } from 'react';
import {
  FormContainer,
  FormField,
  FormFieldInput,
  FormFieldPhone,
  FormFieldTextarea,
} from 'components/forms/FormField';
import { VStack } from '@chakra-ui/react';
import { toastSuccess } from 'utils';
import { useApiForm } from 'hooks';
import Button from 'components/shared/Button';
import { Alert } from 'components/shared/Alert';
import { api } from '@tlsocial/common';

const WaitlistForm = props => {
  const [joinWaitlist] = api.useJoinWaitlistMutation();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const onSubmitSuccess = data => {
    setIsSubmitted(true);
  };

  const initialData = {};

  const form = useApiForm(joinWaitlist, initialData, onSubmitSuccess);

  if (isSubmitted) {
    return <Alert success title="You're on the waitlist!" />;
  } else {
    return (
      <FormContainer form={form}>
        <FormFieldInput isRequired label="City" {...form.register('city')} />
        <FormFieldInput isRequired label="Email" {...form.register('email')} />
        {/* <FormField
          isRequired
          label="Social profile(s) (add at least one)"
          country={'us'}
          // desc="Add at least one social profile by entering your profile URLs or usernames."
        >
          <VStack spacing={2}>
            <FormFieldInput
              placeholder="Instagram"
              // left={<Icon as={FaInstagram} color="gray.400" />}
              {...form.register('social_instagram')}
            />
            <FormFieldInput
              placeholder="LinkedIn"
              // left={<Icon as={FaLinkedinIn} color="gray.400" />}
              {...form.register('social_linkedin')}
            />
            <FormFieldInput
              placeholder="Twitter"
              // left={<Icon as={FaTwitter} color="gray.400" />}
              {...form.register('social_twitter')}
            />
          </VStack>
        </FormField> */}

        <Button size="lg" {...form.registerSubmit} label={'Join Waitlist'} />
      </FormContainer>
    );
  }
};

export default WaitlistForm;
