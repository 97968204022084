import React from 'react';
import { lib, api, redux } from '@tlsocial/common';
import LocationItem from '../locations/LocationItem';
import ProposalItem from 'components/proposals/ProposalItem';
import { useFetch, useUpcomingEvents } from '../../hooks/';
import Skeleton from 'react-loading-skeleton';
import { useEffect } from 'react';
import { useAsync } from 'react-use';
import { apiFetch } from '../../utils';
import { Box, Flex, GridItem, SimpleGrid, Stack, VStack, StackDivider, Text, Link, Wrap } from '@chakra-ui/react';
import MyHelmet from 'components/shared/MyHelmet';
import { H1, H2, H3 } from 'components/shared/Headings';
import { useParams } from 'react-router-dom';
import { ContentContainer, MaxContainer, MaxContentContainer } from 'components/shared/MaxContainer';
import Button from 'components/shared/Button';
import { linksTo, paths } from '../../constants';
import Spinner from 'components/shared/Spinner';
import UpcomingEvents from 'components/shared/UpcomingEvents';
import CityUpdateItem from './CityUpdateItem';
import ContentLoading from 'components/shared/ContentLoading';
import Avatar from 'components/people/Avatar';

const MIN_RECENT_ATTENDEES = 4;

const CityScreen = props => {
  const { cityKey } = useParams();

  const { data: city, isLoading: isLoadingCity } = api.useGetCityFromKeyQuery(cityKey, { skip: !cityKey });
  const cityId = city?.id;

  const { data: locations = {}, isLoading: isLoadingLocations } = api.useGetFeaturedLocationsQuery(cityId, {
    skip: !cityId,
  });

  const { data: updates, isLoading: isLoadingUpdates } = api.useGetCityUpdatesQuery(cityId, { skip: !cityId });

  const { data: { profiles } = {}, isLoading: isLoadingProfiles } = api.useGetPrevGuestsQuery(
    {
      cityId,
      eventType: 1, //change
    },
    { skip: !cityId },
  );

  const { upcomingEvents, isLoadingEvents } = useUpcomingEvents(cityId);

  const isLoading = isLoadingCity || isLoadingLocations || isLoadingUpdates || isLoadingProfiles || isLoadingEvents;

  const renderLocations = () => {
    if (locations?.length > 0) {
      return (
        <Box>
          <H2 mb={7}>SocialTable locations</H2>
          <SimpleGrid columns={{ base: 1, sm: 2, md: 2, lg: 2 }} spacing={6}>
            {locations.map((location, i) => (
              <GridItem key={location.id}>
                <LocationItem location={location} />
              </GridItem>
            ))}
          </SimpleGrid>
        </Box>
      );
    }
  };

  const renderUpcomingEvents = () => {
    if (upcomingEvents?.length > 0) {
      return (
        <Box>
          <H2 mb={7}>Upcoming events</H2>
          {isLoadingEvents && <Spinner />}
          <UpcomingEvents items={upcomingEvents} />
        </Box>
      );
    }
  };

  const renderPrevAttendees = () => {
    if (profiles.length <= MIN_RECENT_ATTENDEES) return null;
    return (
      <Box>
        <H3 mb={6}>Recent attendees</H3>
        <Wrap spacing={3}>
          {profiles.map((profile, index) => {
            return <Avatar size={60} key={index} profile={profile} />;
          })}
        </Wrap>
      </Box>
    );
  };

  const renderUpdates = () => {
    if (updates?.length === 0) return;
    return (
      <Box>
        <H3 mb={6}>City updates</H3>
        {/* {isLoadingUpdates && <Spinner />} */}

        <Stack spacing={12} divider={<StackDivider borderColor="gray.200" borderWidth={2} />}>
          {updates?.length > 0 &&
            updates.map((update, key) => {
              return <CityUpdateItem key={key} update={update} />;
            })}
        </Stack>
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <Box bg="light" pt={3}>
        <MyHelmet title={city?.name} />
        <Stack direction={['column', 'column', 'row']} spacing={12}>
          <Box flex={5}>
            <Stack
              spacing={12}
              // divider={<StackDivider borderColor="gray.200" borderWidth={5} />}
            >
              {renderUpcomingEvents()}
              {renderLocations()}
            </Stack>
          </Box>
          <Stack spacing={12} flex={2}>
            <Button {...linksTo.CITY_APPLY(city)} label="Apply to Join" w="full" />
            {renderPrevAttendees()}
            {renderUpdates()}
          </Stack>
        </Stack>
      </Box>
    );
  };

  return <ContentLoading hasContent={city} empty="City cannot be found." {...{ isLoading, renderContent }} />;
};

export default CityScreen;
