import React from 'react';
import * as c from '../../constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import LocationItem from '../locations/LocationItem';
import AmbassadorItem from '../people/AmbassadorItem';
import { useFetch } from '../../hooks/';
import Skeleton from 'react-loading-skeleton';
import { useEffect } from 'react';
import { useAsync } from 'react-use';
import { apiFetch } from '../../utils';
import { Box, Flex, GridItem, SimpleGrid } from '@chakra-ui/react';
import MyHelmet from 'components/shared/MyHelmet';
import { H1, H2, H3 } from 'components/shared/Headings';
import { useParams } from 'react-router-dom';
import { ContentContainer, MaxContainer, MaxContentContainer } from 'components/shared/MaxContainer';
import ApplyForm from 'components/apply/ApplyForm';
import ApplyContent from 'components/apply/ApplyContent';

const CityApplyScreen = props => {
  const { cityKey } = useParams();

  // const { data: cities, isLoading } = api.useGetActiveCitiesQuery();
  // const city = cities && cities.find(city => city.city_key === cityKey);

  return (
    <>
      <H1 mb={5}>Join our exclusive dining community</H1>
      <ApplyContent />
    </>
  );
};

export default CityApplyScreen;
