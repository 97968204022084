import { initPlatform } from '@tlsocial/common/platformConfig';
import storage from 'redux-persist/lib/storage';
import { toastError } from './utils/generalUtils';
import Bugsnag from '@bugsnag/js';

// NOTE: DO NOT IMPORT ANYTHING THAT WILL LOAD REDUX STORE
// If you do, the storage will not be set prior to redux setup.

export const configurePlatform = () => {
  initPlatform({
    reduxStorage: storage,
    toastError: toastError,
    bugsnagNotify: Bugsnag.notify,
    bugsnagSetUser: Bugsnag.setUser,
  });
};
