import { useDisclosure } from '@chakra-ui/react';
import { lib, api, redux } from '@tlsocial/common';
import { ConfirmModal } from 'components/shared/Modals';
import { MODALS } from 'constants';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { analytics } from '../api/analyticsApi';
import { useModal } from './hookUtils';

export const useMountedState = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return isMounted;
};

export const useAsyncFunc = (asyncFunc, errorFunc, initialLoadingState = false) => {
  const [isLoading, setLoading] = useState(initialLoadingState);
  const [value, setValue] = useState(null);
  const isMounted = useMountedState();
  const run = async props => {
    setLoading(true);
    try {
      const returnValue = await asyncFunc(props);
      if (isMounted()) {
        setValue(returnValue);
        setLoading(false);
      }
      return returnValue;
    } catch (err) {
      if (isMounted()) {
        setLoading(false);
      }
      if (errorFunc) errorFunc();
      lib.error(err);
      // console.log({ err });
    }
  };
  return { isLoading, value, run };
};

export const useAsyncFuncOnce = (...props) => {
  const { isLoading, value, run } = useAsyncFunc(...props);
  useEffectOnce(run);
  return { isLoading, value };
};
