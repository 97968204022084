import { transformProfiles } from './apiConstruct';
import { loadSuggested } from '../redux/citySuggestedSlice';
import { apiSlice } from './apiSlice';

export const apiUsers = apiSlice.injectEndpoints({
  endpoints: build => ({
    getPendingProfiles: build.query({
      query: () => `/users/pending`,
      transformResponse: transformProfiles,
    }),
    getProfilesMeet: build.query({
      query: cityId => `/users/city/${cityId}/meet`,
      transformResponse: transformProfiles,
    }),
    getProfilesStarred: build.query({
      query: cityId => `/users/city/${cityId}/starred`,
      transformResponse: transformProfiles,
    }),
    getSuggested: build.query({
      query: cityId => `/users/city/${cityId}/suggested`,
      transformResponse: transformProfiles,
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(loadSuggested(data.profiles));
      },
    }),
    getCityMembers: build.query({
      query: cityId => `/users/city/${cityId}/members`,
      transformResponse: transformProfiles,
    }),
    getGroupProfiles: build.query({
      query: ({ cityId, groupId }) => `/users/city/${cityId}/group/${groupId}`,
      transformResponse: transformProfiles,
    }),
    getProfileMet: build.query({
      query: profileId => `/users/profile/${profileId}/met`,
      transformResponse: transformProfiles,
    }),
    getProfileInvited: build.query({
      query: profileId => `/users/profile/${profileId}/invited`,
      transformResponse: transformProfiles,
    }),
    getLocationProfiles: build.query({
      query: locationId => `/users/location/${locationId}/wishlist`,
      transformResponse: transformProfiles,
    }),
    getProposalProfiles: build.query({
      query: proposalId => `/users/proposal/${proposalId}/wishlist`,
      transformResponse: transformProfiles,
    }),
    getPrevGuests: build.query({
      query: ({ cityId, eventType }) => `/users/city/${cityId}/event_type/${eventType}/prev_attendees`,
      transformResponse: transformProfiles,
    }),
    getProfile: build.query({
      query: id => `/users/${id}`,
      transformResponse: transformProfiles,
    }),
    getAmbassadors: build.query({ query: id => `/users/city/${id}/ambassadors` }),
    rateUser: build.mutation({
      query: body => ({ url: `users/${body.user_to_id}/rate`, method: 'PUT', body }),
    }),
    reportUser: build.mutation({
      query: body => ({ url: `users/${body.user_to_id}/report`, method: 'PUT', body }),
    }),
    removeConnection: build.mutation({
      query: body => ({ url: `users/${body.user_to_id}/remove_connection`, method: 'PUT', body }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetProfilesMeetQuery,
  useGetProfilesStarredQuery,
  useGetSuggestedQuery,
  useGetCityMembersQuery,
  useGetGroupProfilesQuery,
  useGetPendingProfilesQuery,
  useGetLocationProfilesQuery,
  useGetProposalProfilesQuery,
  useGetPrevGuestsQuery,
  useGetProfileQuery,
  useGetProfileMetQuery,
  useGetProfileInvitedQuery,
  useGetAmbassadorsQuery,
  useRateUserMutation,
  useRemoveConnectionMutation,
  useReportUserMutation,
} = apiUsers;
