import React from 'react';
import MyHelmet from 'components/shared/MyHelmet';
import * as utils from '../../utils';
import { lib, api, redux } from '@tlsocial/common';
import Spinner from '../shared/Spinner';
import { MaxContainer, MaxContentContainer } from '../shared/MaxContainer';
import { H1, H2, H3 } from '../shared/Headings';
import ContentLoading from 'components/shared/ContentLoading';
import { useLocation, useParams } from 'react-router-dom';
import { useObjectOrFetch } from 'hooks/useObjectOrFetch';
import * as c from '../../constants';
import { IconRow } from 'components/shared/IconRow';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faTicket } from '@fortawesome/pro-light-svg-icons/faTicket';
import { faLocationArrow } from '@fortawesome/pro-light-svg-icons/faLocationArrow';
import { FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import Button from '../shared/Button';
import LocationItem from 'components/locations/LocationItem';
import Avatar from 'components/people/Avatar';
import HowItWorksContainer from 'components/shared/HowItWorksContainer';

import { Box, Text, ButtonGroup, IconButton, Stack, StackDivider, Show, Wrap, HStack, Flex } from '@chakra-ui/react';
import { faLocationPin, faMapPin, faUser, faUserAlt, faUsers } from '@fortawesome/pro-light-svg-icons';
import { linksTo, paths } from '../../constants';
import { Link } from 'components/shared/Links';
import { usePool } from 'hooks/usePool';

const MAX_SHOW_LOCATIONS = 2;

const PoolScreen = props => {
  const { slug } = useParams();
  const poolId = lib.fromSlug(slug);
  const formatPrice = lib.useFormatPrice();
  const { object: pool, isLoading: isLoadingPool } = useObjectOrFetch(api.useGetPoolQuery, poolId);
  const cityId = pool?.city_id;

  const { data: { profiles } = {}, isLoading: isLoadingProfiles } = api.useGetPrevGuestsQuery(
    {
      cityId: cityId,
      eventType: pool?.event_type,
    },
    { skip: !cityId },
  );
  const { data: appData, isLoading: isLoadingAppData } = api.useGetAppDataWebQuery();
  const { data: city, isLoading: isLoadingCity } = api.useGetCityQuery(cityId, { skip: !cityId });
  const { data: locations, isLoading: isLoadingLocations } = api.useGetFeaturedLocationsQuery(cityId, {
    skip: !cityId,
  });

  // console.log('profiles', profiles);

  const bgImage = city && pool && lib.getPoolImageSource(pool, city);

  const { typeData, cityTimeEvent, cityTimeMatching } = usePool(pool);

  const renderAttendButton = () => {
    return city && <Button {...linksTo.CITY_APPLY(city)} size="lg" label="Attend" w="full" />;
  };

  const renderLocations = () => {
    if (!isLoadingPool && pool?.location) return;

    if (isLoadingLocations || locations?.length > 0) {
      return (
        <Box>
          <Flex alignItems="center" mb={5}>
            <H3 flex={1}>{locations?.length} Possible locations</H3>
            <Box>
              {city && locations?.length > MAX_SHOW_LOCATIONS && (
                <Button {...linksTo.CITY(city)} variant="ghost" size="sm" label="See all" w="full" ml="auto" />
              )}
            </Box>
          </Flex>
          {isLoadingLocations && <Spinner />}

          {!isLoadingLocations && locations?.length > 0 && (
            <Stack spacing={6}>
              {locations.slice(0, MAX_SHOW_LOCATIONS).map((location, i) => (
                <LocationItem key={location.id} location={location} />
              ))}
            </Stack>
          )}

          {city && locations?.length > MAX_SHOW_LOCATIONS && (
            <Button
              mt={6}
              {...linksTo.CITY(city)}
              variant="ghost"
              label={`See all ${locations?.length} Locations`}
              w="full"
              ml="auto"
            />
          )}
        </Box>
      );
    }
  };

  const renderAttendees = () => {
    const MIN_RECENT_ATTENDEES = 10;
    if (!isLoadingProfiles && profiles.length < MIN_RECENT_ATTENDEES) return;

    return (
      <Box>
        <H3 mb={7}>Recent attendees</H3>

        {isLoadingProfiles && <Spinner />}

        {!isLoadingProfiles && profiles.length > 0 && (
          <Wrap spacing={3}>
            {profiles.map((profile, index) => {
              return (
                <Link to={c.paths.JOIN} key={profile.id}>
                  <Avatar size={60} profile={profile} />
                </Link>
              );
            })}
          </Wrap>
        )}
      </Box>
    );
  };

  const renderShareSocial = () => {
    return (
      <Box>
        <H3 mb={5}>Share with friends</H3>
        <ButtonGroup variant="ghost">
          <IconButton
            as="a"
            href="https://instagram.com/socialtable.app"
            target="_blank"
            aria-label="Instagram"
            icon={<FaInstagram fontSize="1.25rem" />}
          />
          <IconButton
            as="a"
            href="https://www.linkedin.com/company/socialtable-app"
            target="_blank"
            aria-label="LinkedIn"
            icon={<FaLinkedin fontSize="1.25rem" />}
          />
          <IconButton
            as="a"
            href="https://twitter.com/Bali_Soc"
            target="_blank"
            aria-label="Twitter"
            icon={<FaTwitter fontSize="1.25rem" />}
          />
        </ButtonGroup>
      </Box>
    );
  };

  const renderDetails = () => {
    return (
      <Stack spacing={3}>
        <IconRow icon={faClock} header={pool.time_event} text={lib.formatFull(cityTimeEvent)} />
        {/* <IconRow icon={faClock} header="Matching time" text={lib.formatFull(cityTimeMatching)} /> */}
        <IconRow icon={faUser} text={typeData.peopleText} />

        {pool?.location ? (
          <IconRow icon={faMapPin} text={`${pool.location?.name} (primary location)`} />
        ) : (
          <IconRow icon={faMapPin} text={typeData.locationText(city?.name)} />
        )}

        <IconRow
          icon={faTicket}
          header="Tickets"
          text={
            pool.ticket_price > 0 ? `${formatPrice(pool.ticket_price, pool.ticket_currency)} booking fee` : 'Free registration'
          }
        />
        <Show below="md">
          <Box pt={5} w="100%">
            {renderAttendButton()}
          </Box>
        </Show>
      </Stack>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Box mb={12} style={{ ...styles.bgImageStyle, backgroundImage: `url(${bgImage})` }}>
          <Box w="100%" h={['200px', '250px', '350px']} justify="center"></Box>
        </Box>

        <Stack direction={['column', 'column', 'row']} spacing={12}>
          <Box flex={5}>
            <H1 mb={10}>{pool.title}</H1>

            <Stack spacing={10} divider={<StackDivider borderColor="gray.200" borderWidth={5} />}>
              {renderDetails()}
              {pool.description && (
                <Box>
                  <H3 mb={7}>About</H3>
                  <Text>{pool.description}</Text>
                </Box>
              )}
              {renderAttendees()}
              <HowItWorksContainer items={typeData?.how} />
            </Stack>
          </Box>
          <Stack spacing={12} flex={2}>
            {renderAttendButton()}
            {renderLocations()}
            {/* {renderShareSocial()} */}
          </Stack>
        </Stack>
      </>
    );
  };

  return (
    <Box bg="light">
      <MyHelmet title={pool?.title} />
      <MaxContainer pb={20}>
        <ContentLoading
          hasContent={pool}
          empty="Event cannot be found."
          {...{ isLoading: isLoadingPool, renderContent }}
        />
      </MaxContainer>
    </Box>
  );
};

const styles = {
  bgImageStyle: {
    backgroundColor: '#000000',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',
    borderRadius: 0,
  },
};

export default PoolScreen;
