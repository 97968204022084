import {
  transformAppData,
  transformChats,
  transformCityData,
  constructCityUpdatesFromAPI,
  transformAppDataWeb,
} from './apiConstruct';
import { apiSlice } from './apiSlice';
import { tags } from './rtkTags';
import { arrayToObject } from '../library';

export const apiApp = apiSlice.injectEndpoints({
  endpoints: build => ({
    getAppData: build.query({
      query: () => `/app/data`,
      transformResponse: transformAppData,
    }),
    getAppDataWeb: build.query({
      query: () => '/app/data_web',
      transformResponse: transformAppDataWeb,
    }),
    trackHit: build.mutation({
      query: body => ({ url: `app/track_hit`, method: 'PUT', body }),
    }),
    getCities: build.query({
      query: () => `/cities`,
      transformResponse: response => arrayToObject(response, 'id'),
    }),
    getCityData: build.query({
      query: id => `/cities/${id}/data`,
      transformResponse: transformCityData,
      providesTags: [tags.CITY],
    }),
    getCity: build.query({
      query: id => `/cities/${id}`,
      providesTags: [tags.CITY],
    }),

    getActiveCities: build.query({ query: () => '/cities' }),

    getCityFromKey: build.query({ query: cityKey => `/cities/key/${cityKey}` }),

    getCityUpdates: build.query({
      query: cityId => `/cities/${cityId}/updates`,
      transformResponse: response => constructCityUpdatesFromAPI(response.city_updates_data, []),
    }),

    apply: build.mutation({ query: body => ({ url: '/app/apply', method: 'PUT', body }) }),
    joinWaitlist: build.mutation({ query: body => ({ url: '/app/join_waitlist', method: 'PUT', body }) }),

    getChats: build.query({
      query: () => `chats`,
      transformResponse: transformChats,
      providesTags: [tags.CHATS],
    }),
    chatSent: build.mutation({
      query: body => ({ url: `/chats/on_sent`, method: 'PUT', body }),
    }),
    testError: build.query({
      query: () => `/test/error`,
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAppDataQuery,
  useGetCityDataQuery,
  useGetCitiesQuery,
  useGetCityQuery,
  useGetChatsQuery,
  useGetAppDataWebQuery,
  useGetActiveCitiesQuery,
  useGetCityFromKeyQuery,
  useGetCityUpdatesQuery,
  useApplyMutation,
  useJoinWaitlistMutation,
  useChatSentMutation,
  useTestErrorQuery,
} = apiApp;
