import { Checkbox, Input, InputGroup, InputLeftElement, InputRightElement, Text, Textarea } from '@chakra-ui/react';
import { trackEvent } from 'api/analyticsApi';
import { events } from 'constants';
import React from 'react';
import ResizeTextarea from 'react-textarea-autosize';
import { forwardRef, Box, BoxProps } from '@chakra-ui/react';

export const MyCheckbox = props => {
  const { value, label, onChange, ...extraProps } = props;

  const onCheckChange = e => {
    const newValue = e.target.checked;
    onChange(newValue);
    trackEvent(events.click_checkbox, { type: props.id, value: newValue });
  };
  return (
    <Checkbox isChecked={value} colorScheme="blue" onChange={onCheckChange} {...extraProps}>
      <Text fontSize="15px">{label}</Text>
    </Checkbox>
  );
};

export const MyTextarea = props => {
  const { onChange, value: origValue, ...extraProps } = props;
  // if undefined set to empty string
  const value = origValue || '';
  return (
    <Textarea
      // ref={ref}
      // ref={tag => (this.textarea = tag)}
      value={value}
      as={ResizeTextarea}
      fontSize="15px"
      minH="100px"
      // variant="filled"
      py={3}
      lineHeight={1.5}
      focusBorderColor="primary.300"
      borderColor="gray.300"
      bg="gray.50"
      onChange={e => onChange(e.target.value)}
      {...extraProps}
    />
  );
};

export const MyInput = props => {
  const { left, right, onChange, integerOnly, value: origValue, groupProps, innerRef, ...extraProps } = props;
  const value = origValue || '';
  const handleChange = e => {
    let newValue = e.target.value;
    if (integerOnly) {
      newValue = parseInt(newValue) || '';
    }
    onChange(newValue);
  };

  const renderInput = () => (
    <Input
      ref={innerRef}
      value={value}
      fontSize="15px"
      variant="outline"
      focusBorderColor="primary.300"
      borderColor="gray.300"
      bg="gray.50"
      maxW={integerOnly && '200px'}
      onChange={handleChange}
      // bg="gray.50"
      {...extraProps}
    />
  );

  if (left || right) {
    return (
      <InputGroup {...groupProps}>
        {left && <InputLeftElement pointerEvents="none" color="gray.400" fontSize="1.2em" children={left} />}
        {renderInput()}
        {right && <InputRightElement children={right} />}
      </InputGroup>
    );
  } else {
    return renderInput();
  }
};
