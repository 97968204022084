import React from 'react';
import MyHelmet from 'components/shared/MyHelmet';
import * as utils from '../../utils';
import { lib, api, redux } from '@tlsocial/common';
import Spinner from '../shared/Spinner';
import { MaxContainer, MaxContentContainer } from '../shared/MaxContainer';
import { H1, H2, H3 } from '../shared/Headings';
import ContentLoading from 'components/shared/ContentLoading';
import { useLocation, useParams } from 'react-router-dom';
import { useObjectOrFetch } from 'hooks/useObjectOrFetch';
import * as c from '../../constants';
import { IconRow } from 'components/shared/IconRow';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { faTicket } from '@fortawesome/pro-light-svg-icons/faTicket';
import { faLocationArrow } from '@fortawesome/pro-light-svg-icons/faLocationArrow';
import { FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { format } from 'date-fns';
import Button from '../shared/Button';
import LocationItem from 'components/locations/LocationItem';
import Avatar from 'components/people/Avatar';
import HowItWorksContainer from 'components/shared/HowItWorksContainer';

import { Box, Center, Flex, Text, Stack, StackDivider, Show } from '@chakra-ui/react';
import { faMapPin } from '@fortawesome/pro-light-svg-icons';
import { paths } from '../../constants';

const ProposalScreen = props => {
  const { slug } = useParams();
  const proposalId = lib.fromSlug(slug);

  const formatPrice = lib.useFormatPrice();
  const { object: proposal, isLoading: isLoadingProposal } = useObjectOrFetch(api.useGetProposalQuery, proposalId);

  const cityId = proposal?.city_id;

  const { data: appData, isLoading: isLoadingAppData } = api.useGetAppDataWebQuery();

  const { data: city, isLoading: isLoadingCity } = api.useGetCityQuery(cityId, { skip: !cityId });
  const { data: { locations } = {}, isLoading: isLoadingLocations } = api.useGetFeaturedLocationsQuery(cityId, {
    skip: !cityId,
  });

  const bgImage = city && proposal && lib.getProposalImageSource(proposal, city);

  const typeData = lib.EVENT_TYPE_DATA[proposal?.event_type];
  const cuisine = lib.arrayToObject(appData?.cuisine, 'id');

  const renderAttendButton = () => {
    return <Button to={paths.JOIN} label="Attend" w="full" />;
  };

  const renderLocations = () => {
    if (!isLoadingProposal && proposal?.location) return;

    if (isLoadingLocations || locations?.length > 0) {
      return (
        <Box>
          <H3 mb={5}>Popular locations</H3>
          {isLoadingLocations && (
            <Box py={10}>
              <Spinner />
            </Box>
          )}

          {!isLoadingLocations && locations?.length > 0 && (
            <Stack spacing={5}>
              {locations.map((location, i) => (
                <Box key={location.id}>
                  <LocationItem location={location} cuisine={cuisine} />
                </Box>
              ))}
            </Stack>
          )}
        </Box>
      );
    }
  };

  const renderDetails = () => {
    const cityTimeEvent = lib.toZoned(proposal.time_event, proposal.city_timezone);

    return (
      <Stack spacing={3}>
        <IconRow icon={faClock} header="Date & time" text={lib.formatFull(cityTimeEvent)} />
        {proposal?.location ? (
          <IconRow icon={faMapPin} text={`${proposal.location?.name} (primary location)`} />
        ) : (
          <IconRow icon={faMapPin} text={typeData.locationText(city?.name)} />
        )}
        {/* <IconRow icon={faUsers} header="Capacity" text="Unlimited capacity. 4-8 people per table reservation" /> */}

        <IconRow
          icon={faTicket}
          header="Tickets"
          text={
            proposal?.ticket_price > 0
              ? `${formatPrice(proposal?.ticket_price, proposal?.ticket_currency)} booking fee`
              : 'Free registration'
          }
        />
        <Show below="md">
          <Box pt={5} w="100%">
            {renderAttendButton()}
          </Box>
        </Show>
      </Stack>
    );
  };

  const renderHost = () => {
    const { host } = proposal;
    return (
      <Flex pb={10} align="center">
        <Avatar size={100} user={host} />

        <Box mx={3}>
          <Text>Hosted By</Text>
          <Text style={{ fontWeight: 700 }}>{host.full_name}</Text>
        </Box>
      </Flex>
    );
  };

  const renderContent = () => {
    return (
      <Box bg="light">
        <MyHelmet title={proposal.title} />

        <MaxContainer pb={20}>
          <Box mb={12} style={{ ...styles.bgImageStyle, backgroundImage: `url(${bgImage?.uri})` }}>
            <Box w="100%" h={['250px', '300px', '350px']} justify="center"></Box>
          </Box>

          <Stack direction={['column', 'column', 'row']} spacing={12}>
            <Box flex={5}>
              <H1 mb={10}>{proposal.title}</H1>
              {renderHost()}
              <Stack spacing={10} divider={<StackDivider borderColor="gray.200" borderWidth={5} />}>
                {proposal?.description && (
                  <Box>
                    <H3 mb={7}>About</H3>
                    <Text color="gray.600">{proposal?.description}</Text>
                  </Box>
                )}

                {renderDetails()}
                <HowItWorksContainer items={typeData?.how} />
              </Stack>
            </Box>
            <Stack spacing={12} flex={2}>
              {renderAttendButton()}
              {renderLocations()}
            </Stack>
          </Stack>
        </MaxContainer>
      </Box>
    );
  };

  return (
    <>
      <ContentLoading
        hasContent={proposal}
        empty="Event cannot be found."
        {...{ isLoading: isLoadingProposal, renderContent }}
      />
    </>
  );
};

const styles = {
  bgImageStyle: {
    backgroundColor: '#000000',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',
    borderRadius: 0,
  },
};

export default ProposalScreen;
