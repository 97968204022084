import {
  Alert as ChakraAlert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  CloseButton,
  Flex,
  Stack,
} from '@chakra-ui/react';
import React from 'react';
import { FaUnlock } from 'react-icons/fa';

export const Alert = props => {
  const { success, warning, error, info, action, Icon, title, desc, canClose, ...extraProps } = props;
  const status = success ? 'success' : warning ? 'warning' : error ? 'error' : info ? 'info' : '';
  return (
    <ChakraAlert status={status} rounded="lg" alignItems="start" {...extraProps} p={5}>
      <AlertIcon as={Icon} />
      <Box w="full">
        {title && (
          <AlertTitle fontSize="16px" maxW="700px">
            {title}
          </AlertTitle>
        )}
        {desc && (
          <AlertDescription fontSize="15px">
            <Box maxW="700px">{desc}</Box>
          </AlertDescription>
        )}
        {props.children && <Box mt={2}>{props.children}</Box>}
      </Box>
      {canClose && <CloseButton position="absolute" right="8px" top="8px" />}
    </ChakraAlert>
  );
};
