// import { RESULTS } from 'react-native-permissions';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  networkConnected: true,
  permissions: {
    notifications: null,
    location: null,
    photos: null,
    contacts: null,
  },
  granted: {
    notifications: false,
    location: false,
    photos: false,
    contacts: false,
  },
  deviceLat: null,
  deviceLng: null,
  bgMessageQueue: [],
  activeScreen: {},
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setNetworkStatus: (state, action) => {
      state.networkConnected = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
      state.granted = {
        notifications: action.payload.notifications === 'granted',
        location: action.payload.location === 'granted',
        photos: action.payload.photos === 'granted' || 'limited',
        contacts: action.payload.contacts === 'granted',
      };
    },
    setLocationCoords: (state, action) => {
      state.deviceLat = action.payload.deviceLat;
      state.deviceLng = action.payload.deviceLng;
    },
    queueBgData: (state, action) => {
      state.bgMessageQueue.push(action.payload);
    },
    clearBgData: (state, action) => {
      state.bgMessageQueue = [];
    },
    setActiveScreen: (state, action) => {
      state.activeScreen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNetworkStatus, setPermissions, setLocationCoords, queueBgData, clearBgData, setActiveScreen } =
  deviceSlice.actions;

export default deviceSlice.reducer;
