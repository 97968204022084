import { parseISO, format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const toZoned = (date, timezone) => {
  return utcToZonedTime(parseISO(date), timezone);
};

export const toZonedFull = (date, timezone) => {
  return formatFull(toZoned(date, timezone));
};

export const formatFull = date => {
  return format(date, 'p - PPPP'); // 7:00 PM - Friday, March 31st, 2023
};
