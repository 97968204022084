import {
  Box,
  ButtonGroup,
  Divider,
  HStack,
  IconButton,
  Img,
  Link as ChakraLink,
  SimpleGrid,
  Stack,
  Text,
  Wrap,
} from '@chakra-ui/react';
import { Link } from 'components/shared/Links';
import { MaxContainer } from 'components/shared/MaxContainer';
import { ButtonsMenu } from 'components/shared/Menus';
import { paths } from '../../constants';
import * as React from 'react';
import { FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const menuButtonProps = {
  padding: 0,
  _hover: {
    bg: 'whiteAlpha.200',
  },
  _active: {
    bg: 'whiteAlpha.100',
  },
};

export const MainFooter = () => {
  const { t, i18n } = useTranslation();
  const links = [
    {
      title: '',
      links: [
        { label: t('links.faq'), to: paths.FAQ },
        { label: t('links.etiquette'), to: paths.ETIQUETTE },
        // { label: t('links.about'), to: paths.ABOUT },
      ],
    },
  ];

  return (
    <Box bg="darkAccent" color="white">
      <MaxContainer>
        <Stack
          justify="space-between"
          align="start"
          direction={{ base: 'column', lg: 'row' }}
          py={{ base: '12', md: '16' }}
          spacing="8">
          <Stack spacing={{ base: '4', md: '6' }} align="start">
            <Text fontSize={30} fontWeight="bold">
              SocialTable
            </Text>
            <Wrap mt={8}>
              <a href="https://apps.apple.com/us/app/socialtable/id1619902829">
                <Img src="/assets/img/app-apple2.png" h="35px" alt="" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.tlsocial.app">
                <Img src="/assets/img/app-android2.png" h="35px" alt="" />
              </a>
            </Wrap>
            {/* <Text color="muted"></Text> */}
          </Stack>
          <SimpleGrid columns={{ base: 2, md: 4 }} gap="8" width={{ base: 'full', lg: 'auto' }}>
            {links.map((group, key) => (
              <Stack key={key} spacing="4" minW={{ lg: '40' }}>
                <Text fontSize="sm" fontWeight="semibold" color="subtle">
                  {group.title}
                </Text>
                <ButtonsMenu
                  noShowActive
                  spacing="4"
                  items={group.links}
                  direction="column"
                  alignItems="start"
                  buttonProps={{ variant: 'link', color: 'primary.300', minWidth: 'none' }}
                />
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
        <Divider />
        <Stack pt="8" pb="12" justify="space-between" direction={{ base: 'column-reverse', md: 'row' }} align="center">
          <Text fontSize="sm">
            © 2022 SocialTable.{' '}
            <Text
              onClick={() => {
                throw new Error('Admin test error');
              }}>
              All
            </Text>{' '}
            rights reserved. &nbsp; &nbsp; <Link to={paths.PRIVACY}>{t('links.privacy')}</Link>&nbsp; &nbsp;
            <Link to={paths.TERMS}>{t('links.terms')}</Link>
          </Text>
          {/* <ButtonsMenu
            items={[
              { isActive: i18n.language === 'en', label: 'EN', onClick: () => i18n.changeLanguage('en') },
              { isActive: i18n.language === 'ja', label: 'JA', onClick: () => i18n.changeLanguage('ja') },
            ]}
            buttonProps={menuButtonProps}
          /> */}
          <ButtonGroup variant="ghost">
            <IconButton
              as="a"
              href="https://instagram.com/socialtable.app"
              target="_blank"
              aria-label="Instagram"
              icon={<FaInstagram fontSize="1.25rem" />}
            />
            <IconButton
              as="a"
              href="https://www.linkedin.com/company/socialtable-app"
              target="_blank"
              aria-label="LinkedIn"
              icon={<FaLinkedin fontSize="1.25rem" />}
            />
          </ButtonGroup>
        </Stack>
      </MaxContainer>
    </Box>
  );
};
