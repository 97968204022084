import React from 'react';
import MyHelmet from 'components/shared/MyHelmet';
import * as utils from '../../utils';
import { lib, api } from '@tlsocial/common';
import Spinner from '../shared/Spinner';
import { MaxContainer, MaxContentContainer } from '../shared/MaxContainer';
import { H1, H2, H3 } from '../shared/Headings';
import ContentLoading from 'components/shared/ContentLoading';
import { useLocation, useParams } from 'react-router-dom';
import { useObjectOrFetch } from 'hooks/useObjectOrFetch';
import * as c from '../../constants';
import { IconRow } from 'components/shared/IconRow';
import { faUtensils } from '@fortawesome/pro-light-svg-icons/faUtensils';
import { faPhone } from '@fortawesome/pro-light-svg-icons/faPhone';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faMapMarkerAlt } from '@fortawesome/pro-light-svg-icons/faMapMarkerAlt';
import { format } from 'date-fns';
import Button from '../shared/Button';
import LocationItem from 'components/locations/LocationItem';
import Avatar from 'components/people/Avatar';

import { Box, Center, Flex, Wrap, Stack, StackDivider, Show, Text } from '@chakra-ui/react';
import { faMapPin } from '@fortawesome/pro-light-svg-icons';
import { paths } from '../../constants';
import { Link } from 'components/shared/Links';
import LocationDetail from './LocationDetail';

const LocationScreen = props => {
  const { slug } = useParams();

  const locationId = lib.fromSlug(slug);

  const { object: location, isLoading: isLoadingLocation } = useObjectOrFetch(api.useGetLocationQuery, locationId);

  const { data: { profiles } = {}, isLoading: isLoadingProfiles } = api.useGetLocationProfilesQuery(locationId);

  const bgImage = locationId && lib.getLocationImageSource(location);

  //   SHAWN: import from shared library instead when available
  const pluralize = count => {
    return count > 1 ? 's' : '';
  };

  const renderJoinButton = () => {
    return <Button to={paths.JOIN} label="Join the Community" w="full" />;
  };

  const renderAbout = () => {
    return (
      <Stack spacing={3}>
        <H1 mb={0}>{location?.name}</H1>
        <LocationDetail location={location} />
        {location.desc && <Text>{location.desc}</Text>}
      </Stack>
    );
  };

  const renderDetails = () => {
    return (
      <Stack spacing={3}>
        {location?.address1 && <IconRow icon={faMapPin} text={`${location.address1}`} />}
        {location?.menu && <IconRow icon={faUtensils} text={`${location?.menu}`} />}
        {location?.website && <IconRow icon={faGlobe} text={`${location?.website}`} href={location?.website} />}
        {location?.phone && <IconRow icon={faPhone} text={`${location?.phone}`} />}
      </Stack>
    );
  };

  const renderUsers = () => {
    if (isLoadingProfiles) return <Spinner />;
    if (profiles?.length === 0) return;

    return (
      <Box>
        <H3 mb={7}>
          {profiles?.length} member{pluralize(profiles?.length)} has {location.name} in their picks
        </H3>

        <Wrap spacing={3}>
          {profiles.map((profile, index) => {
            return (
              <Link to={c.paths.JOIN} key={profile.id}>
                <Avatar size={50} profile={profile} />
              </Link>
            );
          })}
        </Wrap>
      </Box>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Box mb={12} style={{ ...styles.bgImageStyle, backgroundImage: `url(${bgImage})` }}>
          <Box w="100%" h={['250px', '300px', '350px']} justify="center"></Box>
        </Box>

        <Stack direction={['column', 'column', 'row']} spacing={12}>
          <Box flex={5}>
            <Stack spacing={10} divider={<StackDivider borderColor="gray.200" borderWidth={5} />}>
              {renderAbout()}
              {renderDetails()}
            </Stack>
          </Box>
          <Box flex={2}>
            {/* {renderJoinButton()} */}
            {renderUsers()}
          </Box>
        </Stack>
      </>
    );
  };

  return (
    <Box bg="light">
      <MyHelmet title={location?.name} />
      <MaxContainer pb={20}>
        <ContentLoading
          hasContent={location}
          empty="Location cannot be found."
          {...{ isLoading: isLoadingLocation, renderContent }}
        />
      </MaxContainer>
    </Box>
  );
};

const styles = {
  bgImageStyle: {
    backgroundColor: '#000000',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: '#ffffff',
    borderRadius: 0,
  },
};

export default LocationScreen;
