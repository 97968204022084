import React from 'react';
import * as c from '../../constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import { Box, Img } from '@chakra-ui/react';
import Squircle from 'components/shared/Squircle';

const SQUIRCLE_CORNER_MULTIPLIER = 0.3;

const Avatar = props => {
  const { profile, profileId, size, ...extra } = props;

  const imgSize = size > 800 ? 'xlarge' : size > 300 ? 'large' : size > 150 ? 'medium' : 'small';
  const source = profile ? lib.getProfileImageSource(profile, imgSize) : lib.getUserImageUrlSimple(profileId, imgSize);

  const cornerRadius = Math.round(size * SQUIRCLE_CORNER_MULTIPLIER);

  return (
    <Squircle width={size} height={size} cornerRadius={cornerRadius} {...extra}>
      <Img src={source} alt="" />
    </Squircle>
  );
};

export default Avatar;
