import { updateUserRTK } from './rtkUpdates';
import { apiSlice } from './apiSlice';
import { resetSuggestedState } from '../redux/citySuggestedSlice';
import { resetcityLocalState } from '../redux/cityLocalSlice';
import { tags } from './rtkTags';
import { transformUserData } from './apiConstruct';
import { arrayToObjectValues } from '../library';

const onQueryReplaceUser = async (dataIn, { dispatch, queryFulfilled }) => {
  const { data } = await queryFulfilled;
  dispatch(updateUserRTK(data.user));
};

const onQueryChangeCity = async (dataIn, { dispatch, queryFulfilled }) => {
  const { data } = await queryFulfilled;
  dispatch(updateUserRTK(data.user));
  // Reset all local city state
  dispatch(resetSuggestedState());
  dispatch(resetcityLocalState());
};

export const apiAccount = apiSlice.injectEndpoints({
  endpoints: build => ({
    getUser: build.query({
      query: () => `/account/current_user`,
      providesTags: [tags.USER],
    }),
    getUserData: build.query({
      query: () => `/account/current_user_data`,
      transformResponse: transformUserData,
      providesTags: [tags.USER_DATA, tags.PAYMENT_METHODS, tags.USER_APPRECIATIONS, tags.BELLS],
    }),
    updateCurrentUser: build.mutation({
      query: body => ({ url: `/account/update_current_user`, method: 'PUT', body }),
      onQueryStarted: onQueryReplaceUser,
    }),
    changeCity: build.mutation({
      query: body => ({ url: `/account/change_city`, method: 'PUT', body }),
      onQueryStarted: onQueryChangeCity,
    }),
    changeCityCurrentLocation: build.mutation({
      query: body => ({ url: `/account/change_city_current_location`, method: 'PUT', body }),
      onQueryStarted: onQueryChangeCity,
    }),
    uploadPhoto: build.mutation({
      query: body => ({ url: `/account/upload_photo`, method: 'POST', body }),
      onQueryStarted: onQueryReplaceUser,
    }),
    setOnboardingDone: build.mutation({
      query: () => ({ url: `/account/set_onboarding_done`, method: 'PUT' }),
      onQueryStarted: onQueryReplaceUser,
    }),
    updateUserCuisine: build.mutation({
      query: body => ({ url: `/account/update_user_cuisine`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          apiSlice.util.updateQueryData('getUserData', undefined, state => {
            state.userCuisine = arrayToObjectValues(data.user_cuisine, 'cuisine_id', 'level');
          }),
        );
      },
    }),
    updateUserGoals: build.mutation({
      query: body => ({ url: `/account/update_user_goals`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          apiSlice.util.updateQueryData('getUserData', undefined, state => {
            state.userGoals = arrayToObjectValues(data.user_goals, 'goal_id', 'level');
          }),
        );
      },
    }),
    updateEventPrefsBase: build.mutation({
      query: body => ({ url: `/account/update_event_prefs_base`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateUserRTK(data.user));
        dispatch(
          apiSlice.util.updateQueryData('getUserData', undefined, state => {
            state.eventPrefs = data.event_prefs_base;
          }),
        );
      },
    }),
    updateEventPrefsCurrency: build.mutation({
      query: body => ({ url: `/account/update_event_prefs_currency`, method: 'PUT', body }),
      async onQueryStarted(dataIn, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(
          apiSlice.util.updateQueryData('getCityData', undefined, state => {
            state.eventPrefsCurrency = data.event_prefs_currency;
          }),
        );
      },
    }),
    updateUserHoods: build.mutation({
      query: body => ({ url: `/account/update_user_hoods`, method: 'PUT', body }),
    }),
    updateUserPermissions: build.mutation({
      query: body => ({ url: `/account/update_user_permissions`, method: 'PUT', body }),
    }),
    updateAppreciationApproved: build.mutation({
      query: body => ({ url: `/account/update_appreciation_approved`, method: 'PUT', body }),
    }),
    approveAllAppreciations: build.mutation({
      query: body => ({ url: `/account/handle_new_appreciations`, method: 'PUT', body }),
      invalidatesTags: [tags.USER_APPRECIATIONS],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetUserDataQuery,
  useGetUserQuery,
  useUpdateCurrentUserMutation,
  useChangeCityCurrentLocationMutation,
  useChangeCityMutation,
  useUploadPhotoMutation,
  useSetOnboardingDoneMutation,
  useUpdateUserCuisineMutation,
  useUpdateUserGoalsMutation,
  useUpdateEventPrefsBaseMutation,
  useUpdateEventPrefsCurrencyMutation,
  useUpdateAppreciationApprovedMutation,
  useApproveAllAppreciationsMutation,
  useUpdateUserHoodsMutation,
  useUpdateUserPermissionsMutation,
} = apiAccount;
