import React from 'react';
import { configurePlatform } from '../../configurePlatform';

// This must be called before App is imported
configurePlatform();

// Accessing App component this way so that configurePlatform runs before App is imported
const App = require('./App').default;

const LoadPlatform = () => {
  return <App />;
};

export default LoadPlatform;
