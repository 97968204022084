import { arrayToObjectValuesList, CHAT_TYPES, MATCH_STATUS, PAIR_INVITE_STATUS, POOL_STATUS } from '../library';
import { arrayToCount, arrayToObject, arrayToObjectList, arrayToObjectValues } from '../library';

export const constructPoolsFromAPI = data => {
  console.log('constructPoolsFromAPI', data);
  const d_registrations = arrayToObject(data.registrations, 'pool_id');
  const pairInvites = data?.pair_invites_data > 0 ? constructPairInvitesFromAPI(data?.pair_invites_data) : [];
  const d_pairInvites = arrayToObjectList(pairInvites, 'pool_id');
  const poolsX = data.pools
    .map(pool => {
      const registration = d_registrations[pool.id];
      return {
        ...pool,
        registration,
        isRegistered: registration?.is_registered,
        pairInvites: d_pairInvites[pool.id] || [],
      };
    })
    .filter(pool => pool.status !== POOL_STATUS.FINISHED || pool.registration?.matching_status === MATCH_STATUS.FAILED);
  return poolsX;
};

export const constructProfilesFromAPI = (profileData, relations) => {
  const profileGroups = arrayToObjectList(profileData.groups, 'user_id');
  const userRelations = relations ? arrayToObject(relations.profile_relations, 'user_to_id') : {};
  const profiles = profileData.profiles.map(profile => ({
    ...profile,
    user_groups: profileGroups[profile.id] || [],
    user_relation: userRelations[profile.id],
  }));
  return { profiles, count: profileData.count };
};

export const constructEventsFromAPI = (eventData, relations) => {
  const d_attendees = arrayToObjectList(eventData.attendees, 'event_id');
  const d_eventRelations = arrayToObject(relations.event_relations, 'event_id');
  const profile_data = constructProfilesFromAPI(eventData.profile_data, relations);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');
  const events = eventData.events.map(event => ({
    ...event,
    attendees: arrayToObject(d_attendees[event.id], 'user_id'),
    attendeeCurrent: d_eventRelations[event.id],
    profiles: d_attendees[event.id] ? d_attendees[event.id].map(a => d_profiles[a.user_id]) : [],
  }));
  return events;
};

export const constructCityUpdatesFromAPI = (updateData, relations) => {
  if (updateData?.updates) {
    const profile_data = constructProfilesFromAPI(updateData.profile_data, relations);
    const d_profiles = arrayToObject(profile_data.profiles, 'id');
    const updates = updateData.updates;
    updates.forEach(update => (update.profile = d_profiles[update.user_id]));
    return updates;
  }
};

export const constructActivityFromAPI = (activityData, relations) => {
  const profile_data = constructProfilesFromAPI(activityData.profile_data, relations);
  const events = constructEventsFromAPI(activityData.events_data, relations);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');
  const d_locations = arrayToObject(activityData.locations, 'id');
  const d_events = arrayToObject(events, 'id');
  const activities = activityData.activity;
  activities.forEach(item => {
    if (item.model_name === 'user') {
      item.profile = d_profiles[item.object_id];
    } else if (item.model_name === 'location') {
      item.location = d_locations[item.object_id];
    } else if (item.model_name === 'event') {
      item.event = d_events[item.object_id];
    }
  });
  return activities;
};

export const constructLeaderboardFromAPI = data => {
  let tagProfiles = {};
  Object.keys(data.leaderboards).forEach(name => {
    const profileData = data.leaderboards[name];
    tagProfiles[name] = constructProfilesFromAPI(profileData, data.relations).profiles;
  });
  return tagProfiles;
};

export const constructPairInvitesFromAPI = data => {
  console.log('constructPairInvitesFromAPI', data);
  const profile_data = constructProfilesFromAPI(data.profile_data, data.relations);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');
  const d_registration_data = arrayToObject(data.pair_registration_data, 'user_id');
  const invites = data.invites.map(inv => ({
    ...inv,
    pairProfile: d_profiles[inv.user_from_id] || d_profiles[inv.user_to_id],
    pairData: d_registration_data[inv.user_from_id] || d_registration_data[inv.user_to_id],
    isPending: inv.invite_status === PAIR_INVITE_STATUS.PENDING,
    isAccepted: inv.invite_status === PAIR_INVITE_STATUS.ACCEPTED,
    isReceiver: Boolean(d_profiles[inv.user_from_id]),
  }));

  return invites;
};

export const constructAppreciationsFromAPI = (apprData, relations) => {
  const profile_data = constructProfilesFromAPI(apprData.profile_data, relations);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');
  const appreciations = apprData.appreciations.map(ap => ({
    ...ap,
    profileFrom: d_profiles[ap.user_from],
    profileTo: d_profiles[ap.user_to],
  }));

  return appreciations;
};

export const constructReviewsFromAPI = reviewData => {
  const profile_data = constructProfilesFromAPI(reviewData.profile_data, reviewData.relations);
  const d_profiles = arrayToObject(profile_data.profiles, 'id');
  const reviews = reviewData.reviews.map(rev => ({
    ...rev,
    profileFrom: d_profiles[rev.user_id],
  }));

  return reviews;
};

export const constructProposalsFromAPI = data => {
  const { proposals, host_profile_data, relations } = data;
  const hostProfileData = constructProfilesFromAPI(host_profile_data, relations);
  const d_userLikes = arrayToObject(relations.proposal_relations, 'proposal_id');
  const d_userRegs = arrayToObject(relations.proposal_registrations, 'proposal_id');
  const d_profiles = arrayToObject(hostProfileData.profiles, 'id');
  const proposalsX = proposals.map(proposal => ({
    ...proposal,
    host: d_profiles[proposal.host_id],
    userLike: d_userLikes[proposal.id],
    userTicket: d_userRegs[proposal.id],
  }));
  return proposalsX;
};

export const constructInviteFromAPI = data => {
  const { invite, profile_data, relations } = data;
  console.log({ data });
  const profileData = constructProfilesFromAPI(profile_data, relations);
  const d_profiles = arrayToObject(profileData.profiles, 'id');
  const inviteX = {
    ...invite,
    user_from: d_profiles[invite.user_id],
  };
  return inviteX;
};

export const constructContactsDataFromAPI = data => {
  const { profile_data, friend_counts, relations } = data;
  const { profiles } = constructProfilesFromAPI(profile_data, relations);
  const d_friendCounts = arrayToObjectValues(friend_counts, 'phone', 'friends');
  const d_profiles = arrayToObject(profiles, 'phone');

  return {
    d_friendCounts,
    d_profiles,
  };
};

// export const constructProfileExtras = data => {
//   const locations = sortRestaurants(data.locations);
//   const peopleMetData = constructProfilesFromAPI(data.people_met_data, data.relations);
//   const events = constructEventsFromAPI(data.events_data, data.relations);
//   const appreciations = constructAppreciationsFromAPI(data.appreciations_data, data.relations);
//   const returnData = { locations, events, peopleMet: peopleMetData.profiles, appreciations };
//   return returnData;
// };

export const constructNewCompliments = data => {
  const totalCount = data.length;
  const tagCountObj = arrayToCount(data);
  const tagCounts = Object.entries(tagCountObj)
    .map(([tagId, count]) => ({ tagId, count }))
    .sort((a, b) => b.count - a.count);
  return { totalCount, tagCounts };
};

// export const processUsersFromAPI = (user, result) => {
//     const relations = arrayToObject(result.relations, "user_to_id");
//     // NOTE: this could be done server side instead
//     const profile_groups =  arrayToObjectList(result.profile_groups, "user_id");
//     let profiles = result.profiles.filter(p => p.id != user.id);
//     profiles = profiles.map(p=>({...p, user_groups: profile_groups[p.id]}));
//     return {profiles, relations};
// };

export const sortRestaurants = restaurants => {
  return restaurants.sort(l => (l.has_image ? -1 : 1));
};

export const transformProfiles = response => {
  return constructProfilesFromAPI(response.profile_data, response.relations);
};

export const transformAppData = response => {
  return {
    config: response.config,
    data: response.data,
    goals: response.goals,
    tags: arrayToObject(response.tags, 'id'),
    compliments: response.tags.filter(tag => tag.is_compliment),
    negative_tags: response.tags.filter(tag => !tag.is_compliment),
  };
};


export const transformAppDataWeb = response => {
  return {
    cuisine: arrayToObject(response.cuisine, 'id'),
  };
};

export const transformCityData = response => {
  console.log({ response });
  return {
    city: response.city,
    locations: response.locations,
    ambassadors: constructProfilesFromAPI(response.ambassadors_data, response.relations).profiles,
    hoods: response.hoods,
    cuisineIds: response.cuisine.map(c => c.id),
    cuisine: arrayToObject(response.cuisine, 'id'),
    cityUpdates: constructCityUpdatesFromAPI(response.city_updates_data, response.relations),
    activities: constructActivityFromAPI(response.activity_data, response.relations),
    recentEvents: constructEventsFromAPI(response.recent_events_data, response.relations),
    favRestaurants: sortRestaurants(response.user_data.user_restaurants),
    userHoods: arrayToObjectValues(response.user_data.user_hoods, 'hood_id', 'level'),
    eventPrefsCurrency: response.user_data.event_prefs_currency,
  };
};

export const transformUserData = response => {
  return {
    d_userGroups: arrayToObject(response.user_groups, 'id'),
    userCuisine: arrayToObjectValues(response.cuisine, 'cuisine_id', 'level'),
    userGoals: arrayToObjectValues(response.goals, 'goal_id', 'level'),
    paymentMethods: response.payment_methods,
    eventPrefs: response.event_prefs_base,
    // userAppreciations: constructAppreciationsFromAPI(response.user_appreciation_data, response.relations),
    bells: response.bells,
  };
};

export const transformChats = response => {
  const { profiles } = constructProfilesFromAPI(response.profile_data, response.relations);
  const d_chats = arrayToObject(response.chats, 'firebase_chat_id');
  const d_chatUserIds = arrayToObjectValuesList(response.chat_users, 'chat_id', 'user_id');
  return {
    d_chats,
    d_chatUserIds,
    d_fchat: arrayToObjectValues(response.chats, 'id', 'firebase_chat_id'),
    d_chatProfiles: arrayToObject(profiles, 'id'),
    d_eventChatId: arrayToObjectValues(response.events, 'id', 'chat_id'),
    d_userToFirebaseChatId: response.chats.reduce((obj, chat) => {
      if (chat.type === CHAT_TYPES.DIRECT) {
        obj[d_chatUserIds[chat.id]] = chat.firebase_chat_id;
      }
      return obj;
    }, {}),
  };
};
