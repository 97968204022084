export const HOW_DINNER = [
  {
    title: `Register in the app`,
    description: `Create a profile, share your interests, and let us know who you'd like to meet. Then, sign up for a delightful dinner where you can connect with others who share your passions.`,
  },
  {
    title: `Dinner matching starts at 3pm`,
    description: `On the day of the event, SocialTable gets to work, carefully crafting groups of 4-8 people based on shared interests and preferences. We'll arrange the restaurant reservation for your group, and you'll receive a notification with your dinner's location and guest list.`,
  },
  {
    title: 'Meet at your selected restaurant',
    description:
      "All you have to do is arrive at the restaurant, greet your new dinner buddies, and dive into an evening filled with scrumptious cuisine and lively conversation. You'll have access to a group chat feature for easy communication before and after your dinner.",
  },
  {
    title: 'Share your experience',
    description:
      "After the event, let us know how your evening went and which attendees you'd like to connect with or meet again. Your insights help us fine-tune our group matching process, ensuring even more enjoyable dinner events in the future.",
  },
];

export const HOW_COFFEE = [
  {
    title: `Register in the app`,
    description: `Share your interests, tell us who you'd like to meet, and sign up for the coffee pool before matching starts at 10am.`,
  },
  {
    title: `Get your coffee match at 10am`,
    description: `On the day of the event, SocialTable pairs participants based on everyone's preferences. You'll receive a notification introducing your coffee buddy, along with the selected coffee shop location.`,
  },
  {
    title: 'Meet at the selected coffee shop',
    description:
      'Simply arrive at the coffee shop for conversation with your new coffee buddy. If needed, you can use our in-app chat feature to communicate before and after the meet-up.',
  },
  {
    title: 'Share your experience',
    description:
      "After the event, let us know how it went and if you'd like to stay connected or meet again. Your insights help us improve our matching process and create even more valuable coffee connections in the future.",
  },
];

export const FAQ_CONTENT = [
  {
    section: 'faq.general.title',
    questions: [
      ['faq.general.how.question', 'faq.general.how.answer'],
      ['faq.general.plus.question', 'faq.general.plus.answer'],
      ['faq.general.cost.question', 'faq.general.cost.answer'],
      ['faq.general.cancel.question', 'faq.general.cancel.answer'],
    ],
  },
  {
    section: 'faq.matching.title',
    questions: [
      ['faq.matching.choose_who.question', 'faq.matching.choose_who.answer'],
      ['faq.matching.restaurants.question', 'faq.matching.restaurants.answer'],
      ['faq.matching.curated.question', 'faq.matching.curated.answer'],
      ['faq.matching.see_who.question', 'faq.matching.see_who.answer'],
    ],
  },
  {
    section: 'faq.attending.title',
    questions: [
      ['faq.attending.experience.question', 'faq.attending.experience.answer'],
      ['faq.attending.hosted.question', 'faq.attending.hosted.answer'],
      ['faq.attending.how_long.question', 'faq.attending.how_long.answer'],
      ['faq.attending.exchange.question', 'faq.attending.exchange.answer'],
    ],
  },
  {
    section: 'faq.after.title',
    questions: [
      ['faq.after.what.question', 'faq.after.what.answer'],
      ['faq.after.meet_again.question', 'faq.after.meet_again.answer'],
    ],
  },
];

// Also, to bring some intrigue, we'll share a fun fact about everyone in the group -- we won't reveal which fact applies to which person -- you'll just have to guess!
// "Each Sunday we invite you to register for dinner matching for the upcoming week. Just give us the days you're available, and we'll reserve you a seat at an exciting group dinner in the coming week. We'll let you know the details of any dinners you've been set up for by Monday morning.",
// "Yes, the attendee list is revealed on the day of your dinner. This gives dinners an exciting spontaneous feel, while at the same time giving people comfort about who they're meeting.",

export const ETIQUETTE_CONTENT = [
  {
    title: 'etiquette.feels_included.title',
    body: 'etiquette.feels_included.body',
  },
  {
    title: 'etiquette.dont_monopolize.title',
    body: 'etiquette.feels_included.body',
  },
  {
    title: 'etiquette.be_present.title',
    body: 'etiquette.be_present.body',
  },
  {
    title: 'etiquette.good_intentions.title',
    body: 'etiquette.good_intentions.body',
  },
];

// {
//   title: 'Respect people as individuals',
//   body: "Everyone has their own story. Nobody wants to be judged based on surface level characteristics that don't define them.",
// },
