import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, ja } from './translations';
// import moment from 'moment';
// import 'moment/locale/ja';

import LanguageDetector from 'i18next-browser-languagedetector';

const languageDetector = new LanguageDetector();

console.log('languageDetector', languageDetector);

// moment.locale(langCode);

const resources = {
  en: { translation: en },
  ja: { translation: ja },
};

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    //language to use if translations in user language are not available
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18n;
