import React from 'react';
import { Heading } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import { H1 } from './Headings';

const MyHelmet = ({ title, description, image, show, ...extraProps }) => {
  return (
    <>
      {show && <H1>{title}</H1>}
      <Helmet {...extraProps}>
        {title && <title>{title}</title>}
        {image && <meta property="og:image" content={image} />}
        {description && <meta property="og:description" content={description} />}
        {description && <meta name="description" content={description} />}
      </Helmet>
    </>
  );
};

export default MyHelmet;
