import { lib } from '@tlsocial/common';
import { generatePath as gp } from 'react-router-dom';

export const paths = {
  HOME: '/',
  PRIVACY: '/privacy',
  TERMS: '/terms',
  JOIN: '/join',
  ABOUT: '/about',
  FAQ: '/faq',
  ETIQUETTE: '/etiquette',
  CITIES: '/cities',
  CITY: '/cities/:cityKey',
  CITY_APPLY: '/cities/:cityKey/apply',
  INVITE: '/invite/:inviteCode',
  POOL: '/upcoming/:slug',
  PROPOSAL: '/hosted/:slug',
  LOCATION: '/locations/:slug',
  // POOL_TYPE: '/cities/:cityKey/:poolType',
};

export const links = {
  CITY: city => gp(paths.CITY, { cityKey: city.city_key }),
  CITY_APPLY: city => gp(paths.CITY_APPLY, { cityKey: city.city_key }),
  POOL: pool => gp(paths.POOL, { slug: lib.toSlug(pool.title, pool.id) }),
  PROPOSAL: proposal => gp(paths.PROPOSAL, { slug: lib.toSlug(proposal.title, proposal.id) }),
  LOCATION: location => gp(paths.LOCATION, { slug: lib.toSlug(location.name, location.id) }),
};

export const linksTo = {
  CITY: object => ({ to: links.CITY(object), state: { object } }),
  CITY_APPLY: object => ({ to: links.CITY_APPLY(object), state: { object } }),
  POOL: object => ({ to: links.POOL(object), state: { object } }),
  PROPOSAL: object => ({ to: links.PROPOSAL(object), state: { object } }),
  LOCATION: object => ({ to: links.LOCATION(object), state: { object } }),
};
