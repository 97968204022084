import { lib, api, redux } from '@tlsocial/common';
import * as c from '../constants';

export const usePool = pool => {
  if (!pool) {
    return {};
  }
  const cityTimeEvent = lib.toZoned(pool.time_event, pool.city_timezone);
  const cityTimeMatching = lib.toZoned(pool.time_matching, pool.city_timezone);
  const typeData = lib.EVENT_TYPE_DATA[pool.event_type];

  return {
    cityTimeEvent,
    cityTimeMatching,
    typeData,
  };
};
