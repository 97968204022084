import React, { useEffect, useMemo, useState } from 'react';
import { Box, Modal as ChakraModal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { ConfirmModal } from 'components/shared/Modals';
import { MODALS } from '../../constants';
// import LoginScreen from 'components/account/LoginScreen';
// import JoinScreen from 'components/account/JoinScreen';

export const ModalContext = React.createContext();
export const ModalConfirmContext = React.createContext();

export const ModalProvider = props => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [state, setState] = useState({
    visible: false,
    modalType: null,
    modalProps: null,
  });

  const open = (modalType, modalProps) => {
    setState({ visible: true, modalType, modalProps });
  };

  const close = () => {
    setState({ visible: false, modalType: null, modalProps: null });
    setConfirmLoading(false);
  };

  const confirm = () => {
    setConfirmLoading(true);
  };

  const contextValue = useMemo(
    () => ({
      open,
      close,
      confirm,
    }),
    [],
  );

  const modalConfirmValue = {
    open,
    close,
    confirm,
    confirmLoading,
  };

  const renderModalType = () => {
    // if (state.modalType === MODALS.LOGIN) return <LoginScreen />;
    // else if (state.modalType === MODALS.JOIN) return <JoinScreen />;
    // else if (state.modalType === MODALS.RATE) return <RateModal {...state.modalProps} />;
    // else if (state.modalType === MODALS.SUPPORT) return <SupportModal {...state.modalProps} />;
    if (state.modalType === MODALS.CONFIRM) return <ConfirmModal {...state.modalProps} />;
  };

  return (
    <ModalContext.Provider value={contextValue}>
      <ModalConfirmContext.Provider value={modalConfirmValue}>
        <ChakraModal isCentered isOpen={state.visible} onClose={close} autoFocus={false}>
          <ModalOverlay />
          <ModalContent>
            {/* <ModalHeader>Modal Title</ModalHeader> */}
            {/* <ModalCloseButton /> */}
            <Box p={7}>{renderModalType()}</Box>
          </ModalContent>
        </ChakraModal>
        {props.children}
      </ModalConfirmContext.Provider>
    </ModalContext.Provider>
  );
};
