import React from 'react';
import CityItem from './CityItem';
import { Box, GridItem, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import MyHelmet from 'components/shared/MyHelmet';
// import { usePag } from 'hooks/usePagination';
import { H2, H3 } from 'components/shared/Headings';
import ContentLoading from 'components/shared/ContentLoading';
import { Link } from 'components/shared/Links';
import { linksTo } from 'constants';
import WaitlistForm from 'components/apply/WaitlistForm';
import { api } from '@tlsocial/common';

const CitiesScreen = props => {
  const { data: cities, isLoading } = api.useGetActiveCitiesQuery();

  console.log('cities', cities);

  const activeCities = cities ? cities.filter(city => city.active && !city.coming_soon) : [];
  const soonCities = cities ? cities.filter(city => city.coming_soon) : [];

  const renderCities = rCities => (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={5}>
      {rCities.map(city => (
        <GridItem key={city.id}>
          <Link {...linksTo.CITY(city)}>
            <CityItem city={city} />
          </Link>
        </GridItem>
      ))}
    </SimpleGrid>
  );

  const renderContent = () => {
    return (
      <Stack spacing={12} mt={5}>
        {activeCities.length > 0 && (
          <Box>
            <H2 mb={5}>Active Cities</H2>
            {renderCities(activeCities)}
          </Box>
        )}

        {soonCities.length > 0 && (
          <Box>
            <H2 mb={5}>Coming soon</H2>
            {renderCities(soonCities)}
          </Box>
        )}

        <Box>
          <H2 mb={5}>Not in your city?</H2>
          <H3 mb={5}>Join the waitlist</H3>
          <WaitlistForm />
        </Box>
      </Stack>
    );
  };

  return (
    <>
      <MyHelmet title="SocialTable cities" />

      <ContentLoading hasContent={cities} empty="No cities yet" {...{ isLoading, renderContent }} />
    </>
  );
};

export default CitiesScreen;
