import { undoPatchOnFail, updateAttendeeRTK, updateUserGroupRTK, updateUserRTK } from './rtkUpdates';
import { setEditGroupsNeedsRefresh } from '../redux/localSlice';
import { apiSlice } from './apiSlice';

export const apiGroups = apiSlice.injectEndpoints({
  endpoints: build => ({
    getGroupsSearch: build.query({
      query: ({ term, cityId }) => `/groups/search/${term}/${cityId}`,
    }),
    getGroupsPopular: build.query({
      query: cityId => `/groups/popular/${cityId}`,
    }),
    getGroupsFeatured: build.query({
      query: cityId => `/groups/featured/${cityId}`,
    }),
    rateGroup: build.mutation({
      query: body => ({ url: `groups/${body.id}/update_level`, method: 'PUT', body }),
      async onQueryStarted(group, { dispatch, queryFulfilled }) {
        // Optimistic update
        const patchResult = dispatch(updateUserGroupRTK(group));
        undoPatchOnFail(patchResult, queryFulfilled);
      },
    }),
    addGroup: build.mutation({
      query: body => ({ url: `/groups/add`, method: 'PUT', body }),
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        const { data: group } = await queryFulfilled;
        // Insert the new group into the store
        dispatch(updateUserGroupRTK(group));
        // Refresh the my interests screen
        dispatch(setEditGroupsNeedsRefresh(true));
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetGroupsSearchQuery,
  useGetGroupsPopularQuery,
  useGetGroupsFeaturedQuery,
  useRateGroupMutation,
  useAddGroupMutation,
} = apiGroups;
