import { sortRestaurants } from './apiConstruct';
import { GROUP_LEVELS, MEET_RATING } from '../library';
import { apiSlice } from './apiSlice';

export const undoPatchOnFail = (patchResult, queryFulfilled) => {
  queryFulfilled.catch(() => patchResult.undo());
};

export const updateChatSortRTK = (fChatId, time) => {
  return apiSlice.util.updateQueryData('getChats', undefined, state => {
    state.d_chats[fChatId].time_sort = time;
  });
};

export const updateUserRTK = user => {
  return apiSlice.util.updateQueryData('getUser', undefined, state => {
    Object.assign(state, user);
  });
};

export const updateCardsRTK = paymentMethods => {
  return apiSlice.util.updateQueryData('getUserData', undefined, state => {
    state.paymentMethods = paymentMethods;
  });
};

export const updateUserGroupRTK = group => {
  return apiSlice.util.updateQueryData('getUserData', undefined, state => {
    if (group.level === GROUP_LEVELS.NONE) {
      delete state.d_userGroups[group.id];
    } else {
      state.d_userGroups[group.id] = group;
    }
  });
};

export const addBellRTK = bell => {
  return apiSlice.util.updateQueryData('getUserData', undefined, state => {
    state.bells = [bell, ...state.bells];
  });
};

export const updateAttendeeRTK = attendeeCurrent => {
  return apiSlice.util.updateQueryData('getUserEvents', undefined, state => {
    const eventId = attendeeCurrent.event_id;
    state.d_events[eventId].attendeeCurrent = attendeeCurrent;
  });
};
export const updateFavLocationsRTK = data => {
  return apiSlice.util.updateQueryData('getCityData', data.location.city_id, state => {
    state.favRestaurants = sortRestaurants(
      data.is_favorite
        ? [data.location, ...state.favRestaurants]
        : state.favRestaurants.filter(res => res.id !== data.location.id),
    );
  });
};

export const updateProfilesMeetStarRTK = (dispatch, cityId, profile, rating, prevRating) => {
  if (cityId === profile.city_id) {
    dispatch(updateProfilesMeetRTK(cityId, profile, rating, prevRating));
    dispatch(updateProfilesStarredRTK(cityId, profile, rating, prevRating));
  }
};

export const updateProfilesMeetRTK = (cityId, profile, rating, prevRating) => {
  console.log({ rating, prevRating });
  return apiSlice.util.updateQueryData('getProfilesMeet', cityId, state => {
    const profiles = state.profiles.filter(p => p.id !== profile.id);
    const prevMeet = prevRating >= MEET_RATING.MEET;
    const nowMeet = rating >= MEET_RATING.MEET;
    if (prevMeet && !nowMeet) {
      state.profiles = profiles;
      state.count--;
    } else if (nowMeet && !prevMeet) {
      state.profiles = [profile, ...profiles];
      state.count++;
    } else if (nowMeet && prevMeet) {
      state.profiles = [profile, ...profiles];
    }
  });
};

export const updateProfilesStarredRTK = (cityId, profile, rating, prevRating) => {
  return apiSlice.util.updateQueryData('getProfilesStarred', cityId, state => {
    const profiles = state.profiles.filter(p => p.id !== profile.id);
    const prevMeet = prevRating >= MEET_RATING.STAR;
    const nowMeet = rating >= MEET_RATING.STAR;
    if (prevMeet && !nowMeet) {
      state.profiles = profiles;
      state.count--;
    } else if (nowMeet && !prevMeet) {
      state.profiles = [profile, ...profiles];
      state.count++;
    } else if (nowMeet) {
      state.profiles = [profile, ...profiles];
    }
  });
};
