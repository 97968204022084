import React from 'react';
import MyHelmet from 'components/shared/MyHelmet';
import {
  Box,
  GridItem,
  HStack,
  Icon,
  Img,
  InputGroup,
  Show,
  SimpleGrid,
  Stack,
  Text,
  useToken,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { H2, H3 } from 'components/shared/Headings';
import { Link } from 'components/shared/Links';
import CityItem from 'components/cities/CityItem';
import { linksTo } from 'constants';
import WaitlistForm from './WaitlistForm';
import ContentLoading from 'components/shared/ContentLoading';
import ApplyForm from './ApplyForm';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { getDeepLink } from 'utils';
import * as c from '../../constants';
import ApplyContent from './ApplyContent';

const ApplyScreen = props => {
  const { t } = useTranslation();
  const darkAccent = useToken('colors', 'darkAccent');

  return (
    <>
      <MyHelmet show title={'Join our exclusive dining community'} />
      <ApplyContent />
    </>
  );
};

export default ApplyScreen;
