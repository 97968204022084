import React from 'react';
import { Box, Flex, GridItem, Img, Show, SimpleGrid, Text, VStack, Wrap } from '@chakra-ui/react';
import MyHelmet from 'components/shared/MyHelmet';
// import { usePag } from 'hooks/usePagination';
import { H2, H3 } from 'components/shared/Headings';
import ContentLoading from 'components/shared/ContentLoading';
import { Link } from 'components/shared/Links';
import { linksTo } from 'constants';
import { useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { useToken } from '@chakra-ui/react';
import Avatar from './Avatar';
import { api } from '@tlsocial/common';

const InviteScreen = props => {
  const { inviteCode } = useParams();

  const darkAccent = useToken('colors', 'darkAccent');

  const { data: invite, isLoading } = api.useGetInviteForRecipientQuery(inviteCode);

  const renderContent = () => {
    const title = `You've been invited by ${invite?.user_from?.full_name}!`;

    const message = invite.phone
      ? `To accept, download the app and join with phone: ${invite.phone} or code: ${invite.code}`
      : `To accept, download the app and join with code: ${invite.code}`;

    return (
      <>
        <Flex>
          <Avatar profile={invite?.user_from} size={100} mr={7} />
          <Box flex={1}>
            <MyHelmet show title={title} />
            <Text style={{ marginBottom: 25 }}>{message}</Text>

            <VStack spacing={8} align="stretch" mt={7}>
              {invite.url && (
                <Show above="sm">
                  <QRCode value={invite.url} size={200} fgColor={darkAccent} />
                </Show>
              )}

              <Wrap>
                <a href="https://apps.apple.com/us/app/socialtable/id1619902829">
                  <Img src="/assets/img/app-apple2.png" h="40px" alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.tlsocial.app">
                  <Img src="/assets/img/app-android2.png" h="40px" alt="" />
                </a>
              </Wrap>
            </VStack>
          </Box>
        </Flex>
      </>
    );
  };

  return (
    <>
      <ContentLoading hasContent={invite} empty="Invalid invite" {...{ isLoading, renderContent }} />
    </>
  );
};

export default InviteScreen;
