import React from 'react';
import * as c from '../../constants/envConstants';
import { lib, api, redux } from '@tlsocial/common';
import ProposalItem from 'components/proposals/ProposalItem';
import { Box, Flex, GridItem, SimpleGrid, Stack, VStack } from '@chakra-ui/react';
import { H1, H2, H3 } from 'components/shared/Headings';
import Spinner from 'components/shared/Spinner';
import UpcomingItem from './UpcomingItem';

const UpcomingEvents = props => {
  const { items } = props;

  if (!items || items.length === 0) return null;

  return (
    <Box>
      <VStack spacing={5} alignItems="stretch">
        {items.map((item, i) => {
          const key = `${item.is_proposal ? 'proposal' : 'pool'}_${item.id}`;
          return <UpcomingItem key={key} item={item} />;
        })}
      </VStack>
    </Box>
  );
};

export default UpcomingEvents;
