import { MaxContainer } from 'components/shared/MaxContainer';
import React from 'react';
import { Outlet } from 'react-router';
import MainHeader from './MainHeader';
import { paths, links, colors, gs } from '../../constants';
import { MainFooter } from './MainFooter';
import { Box, Flex } from '@chakra-ui/react';

const PageTemplate = props => {
  return (
    <Flex height="100vh" direction="column">
      <MainHeader />
      <Box flex={1}>
        <Outlet />
      </Box>
      <MainFooter />
    </Flex>
  );
};
export default PageTemplate;
