import { createSlice } from '@reduxjs/toolkit';
import { apiAuth } from '../api';

let initialState = {
  appToken: null,
  language: null,
  bools: {
    // howGroupsWork: false,
    seenRatingIntro: false,
  },
  bellsSeen: {},
  screens: {},
  currentTime: null,
  editGroupsNeedsRefresh: false,
  cityLastSeenNewMembers: {},
  cityLastSeenCityUpdates: {},
  splashInviteCode: null,
  calendarIds: {},
  // inviteAccepting: null,
};
// c.ONBOARDING_SCREENS_APPLY.forEach(screenName => (initialState.screens[screenName] = false));

const setToken = (state, { payload }) => {
  state.appToken = payload.key;
};

export const localSlice = createSlice({
  name: 'local',
  initialState,
  reducers: {
    resetLocalState: (state, action) => {
      return {
        ...initialState,
        appToken: state.appToken,
      };
    },
    resetAllState: state => {
      // We handle this in store.js
    },
    setAppToken: (state, { payload }) => {
      state.appToken = payload.key;
    },
    setBool: (state, { payload }) => {
      state.bools[payload.key] = payload.value;
    },
    setBellSeen: (state, action) => {
      state.bellsSeen[action.payload] = true;
    },
    setAllBellsSeen: (state, action) => {
      state.bellsSeen = action.payload;
    },
    setAppLang: (state, action) => {
      state.language = action.payload;
    },
    setScreenDone: (state, action) => {
      state.screens[action.payload] = true;
    },
    setCurrentTime: (state, action) => {
      state.currentTime = action.payload;
    },
    setEditGroupsNeedsRefresh: (state, action) => {
      state.editGroupsNeedsRefresh = action.payload;
    },
    setLastSeenNewMembers: (state, action) => {
      const payload = action.payload;
      state[payload.cityId] = {
        ...state[payload.cityId],
        lastSeenNewMembers: payload.lastSeen,
      };
    },
    setLastSeenCityUpdates: (state, action) => {
      const payload = action.payload;
      state[payload.cityId] = {
        ...state[payload.cityId],
        lastSeenCityUpdates: payload.lastSeen,
      };
    },
    setSplashInviteCode: (state, action) => {
      state.splashInviteCode = action.payload;
    },
    setInviteAccepting: (state, action) => {
      state.inviteAccepting = action.payload;
    },
    setCalendarId: (state, action) => {
      const { id, calEventId } = action.payload;
      console.log({ ...state });
      if (!state.calendarIds) {
        state.calendarIds = {};
      }
      state.calendarIds[id] = calEventId;
    },
  },
  extraReducers(builder) {
    builder
      .addMatcher(apiAuth.endpoints.testLoginAsNewUser.matchFulfilled, setToken)
      .addMatcher(apiAuth.endpoints.loginWithEmail.matchFulfilled, setToken)
      .addMatcher(apiAuth.endpoints.loginWithPhone.matchFulfilled, setToken);
  },
});

// Action creators are generated for each case reducer function
export const {
  resetAllState,
  setAppToken,
  setBool,
  setBellSeen,
  setAllBellsSeen,
  setAppLang,
  resetLocalState,
  setScreenDone,
  setCurrentTime,
  setEditGroupsNeedsRefresh,
  setLastSeenNewMembers,
  setLastSeenCityUpdates,
  setSplashInviteCode,
  setInviteAccepting,
  setCalendarId,
} = localSlice.actions;
