import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { env } from '../library';
// import { analytics } from '../api/analyticsApi';
import { tags } from './rtkTags';
import { resetAllState } from '../redux/localSlice';
import { store } from '../redux/store';
import { toastError } from '../platformConfig';

const handleCustomAPIError = responseData => {
  console.log('handleCustomAPIError', responseData);
  const { error_message } = responseData;
  if (error_message && error_message.includes('Invalid token')) {
    // If there was an invalid token it's a special case
    store.dispatch(resetAllState());
    // analytics.trackEvent(analytics.e.error_fetch_invalid_token);
    toastError('Invalid login. Please sign in again.');
  } else {
    // analytics.trackEvent(analytics.e.error_fetch_custom);
    toastError(error_message);
  }
  // Need to send the data back through redux.
  // So, for example, we could indicate which form fields had errors
  // return Promise.reject(responseData);
};

const bq = fetchBaseQuery({
  baseUrl: `${env.API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().local.appToken;
    if (token) {
      headers.set('authorization', `Token ${token}`);
    }
    return headers;
  },
});
const customBaseQuery = async (...args) => {
  const result = await bq(...args);
  console.log('customBaseQuery', result);
  if (result.error) {
    if (result.error.data?.has_error) {
      handleCustomAPIError(result.error.data);
    } else {
      toastError('Unknown server error');
    }
    return result;
  } else {
    return { ...result, data: result.data.data };
  }
};

export const apiSlice = createApi({
  reducerPath: 'api',
  keepUnusedDataFor: 60,
  baseQuery: customBaseQuery,
  tagTypes: Object.values(tags),
  endpoints: build => ({}),
});
