import { Heading } from '@chakra-ui/react';
import React from 'react';

export const H1 = props => {
  return <Heading as="h1" mb={5} size="xl" {...props} />;
};
export const H2 = props => {
  return <Heading as="h2" size="lg" {...props} />;
};

export const H3 = props => {
  return <Heading as="h3" size="md" {...props} />;
};
