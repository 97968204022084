import { MaxContentContainer } from 'components/shared/MaxContainer';
import React from 'react';
import { Outlet } from 'react-router';

const ContentTemplate = props => {
  return (
    <MaxContentContainer pb={10}>
      <Outlet />
    </MaxContentContainer>
  );
};
export default ContentTemplate;
