import { paths, links, colors, gs, events } from '../constants';
import * as analyticsEvents from '../constants/analyticsConstants';
import { useLocation } from 'react-router';
import { useEffect } from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserProperties, setUserId } from 'firebase/analytics';
import { lib, api, redux } from '@tlsocial/common';
import * as c from '../constants';

// Initialize Firebase
const app = initializeApp(c.env.FIREBASE_CONFIG);
const fa = getAnalytics(app);

export const useAnalytics = () => {
  // Track pageviews
  // const { user } = useUser();
  let location = useLocation();
  const url = location.pathname + location.search;
  useEffect(() => {
    trackEvent(events.route_view, { url });
  }, [url]);

  // // Set user props
  // useEffect(() => {
  //   setUserProps(user);
  // }, [user]);
};

export const setUserProps = user => {
  // if (user) {
  //   const properties = {
  //     user_id: user.id,
  //   };
  //   setUserId(fa, user.id.toString());
  //   setUserProperties(fa, properties);
  // }
};

export const clearUser = () => {
  setUserId(null);
};

export const trackEvent = (event, customParams) => {
  //   const { activeScreen } = store.getState().device;

  //   const fromTime = customFromTime || moment(activeScreen.screenLoadTime);
  //   const app_elapsed_seconds = moment().diff(fromTime, 'seconds', true) || null;
  //   const app_from_screen = activeScreen.fullScreenName;
  //   const app_from_name = activeScreen.objName;
  //   const app_from_id = activeScreen.objId;
  //   const params = { app_elapsed_seconds, app_from_screen, app_from_name, app_from_id, ...customParams };
  const params = customParams;
  logEvent(fa, event, params);

  console.log(`Event: ${event}`, params);

  //   if (pushDatabase) {
  //     api.trackHit({ event, params });
  //   }
};

// timedEvent = (event, fromTime, params) => {
//   this.trackEvent(event, params, true, true, fromTime);
// };

// trackCharge(amount, params) {}
