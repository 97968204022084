import React from 'react';
import MyHelmet from 'components/shared/MyHelmet';
import { Box, Text } from '@chakra-ui/react';
import { H3 } from 'components/shared/Headings';
import { useTranslation } from 'react-i18next';
import { lib, api, redux } from '@tlsocial/common';

const FaqScreen = props => {
  const { t } = useTranslation();

  const renderSection = (section, key) => {
    return (
      <Box key={key}>
        <H3 mb={7} mt={10}>
          <u>{t(section.section)}</u>
        </H3>
        {section.questions.map((q, ii) => (
          <Box key={ii} mb={7}>
            <H3 mb={2}>{t(q[0])}</H3>
            <Text color="gray.700">{t(q[1])}</Text>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <>
      <MyHelmet show title={t('faq.title')} />
      <Box maxW="700px">
        {lib.FAQ_CONTENT.map(renderSection)}

        <h4 className="pt-5">
          <b>{t('faq.more.title')}</b>
        </h4>
        <p>{t('faq.more.text')}</p>
      </Box>
    </>
  );
};

export default FaqScreen;
