import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ModalContext, ModalConfirmContext } from 'components/main/ModalContext';
import { useAsyncFunc } from './useAsync';
import { MODALS } from 'constants';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import AppRouter from 'components/main/AppRouter';
import { useBreakpointValue } from '@chakra-ui/react';

export const useURLParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const add = newParams => {
    setSearchParams({ ...params, ...newParams });
  };
  const set = newParams => {
    setSearchParams(newParams);
  };
  return { set, add, params };
};

export const useIsFullWidth = size => {
  return useBreakpointValue({ base: false, [size]: true });
};
