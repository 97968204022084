import {
  EVENT_TYPE,
  REGISTRATION_HOUR_CLOSE_COFFEE,
  REGISTRATION_HOUR_CLOSE_DINNER,
  REGISTRATION_HOUR_CLOSE_HAPPY_HOUR,
  REGISTRATION_HOUR_CLOSE_LUNCH,
} from './libBackendConstants';

export const POOL_PLANNED_DATA = {
  nameShort: 'poolPlannedData.nameShort',
  fieldName: 'all',
};

import { HOW_DINNER, HOW_COFFEE } from './libContent';

export const EVENT_TYPE_DATA = {
  [EVENT_TYPE.DINNER]: {
    eventType: EVENT_TYPE.DINNER,
    nameShort: 'dinner',
    nameLong: 'Influencer / creator group dinner ',
    eventName: 'Dinner',
    timing: 'tonight',
    poolCloseHour: REGISTRATION_HOUR_CLOSE_DINNER,
    eventDescription: city => `Join 4-8 people at a surprise restaurant in ${city.name}.`,
    howDescription: weekday =>
      `Register ${toUpperFirst(
        weekday,
      )} before 3pm and our algorithm will match you to a table of 4-8 people based on your goals, interests, and who you'd like to meet.`,
    howDescription2: city =>
      `Join 4-8 people at a surprise restaurant in ${city.name}. Who you're meeting and where you're dining is based on your matching preferences.`,
    // locationText: (cityName) =>
    //   `A surprise restaurant in ${cityName} selected based on your budget, cuisine preferences, and restaurant wishlist.`,
    locationText: cityName => `A surprise restaurant in ${cityName}`,
    peopleText: '4-8 people per table',
    venue: 'restaurant',
    attendeesRange: '4-8',
    fieldName: 'dinner',
    genderMixField: 'gender_mix_dinner',
    how: HOW_DINNER,
  },
  [EVENT_TYPE.HAPPY_HOUR]: {
    eventType: EVENT_TYPE.HAPPY_HOUR,
    nameShort: 'eventTypeData.happyHour.nameShort',
    nameLong: 'eventTypeData.happyHour.nameLong',
    eventName: 'eventTypeData.happyHour.eventName',
    timing: 'eventTypeData.happyHour.timing',
    poolCloseHour: REGISTRATION_HOUR_CLOSE_HAPPY_HOUR,
    eventDescription: city => `Join 6-12 people at a surprise bar in ${city.name}.`,
    howDescription: weekday =>
      `Register ${toUpperFirst(
        weekday,
      )} before 3pm and our algorithm will match you to a group of 6-12 people based on your goals, interests, and who you'd like to meet.`,
    howDescription2: city =>
      `Join 6-12 people at a surprise bar in ${city.name}. Who you're meeting and where you're dining is based on your matching preferences.`,
    locationText: cityName => `A surprise bar in ${cityName} chosen based on your preferences and wishlist.`,
    peopleText: 'eventTypeData.happyHour.peopleText',
    venue: 'location',
    attendeesRange: '6-12',
    fieldName: 'happy',
    genderMixField: 'gender_mix_dinner',
    how: HOW_DINNER,
  },
  [EVENT_TYPE.LUNCH]: {
    eventType: EVENT_TYPE.LUNCH,
    nameShort: 'lunch',
    nameMixed: 'coffee/lunch',
    nameLong: 'eventTypeData.lunch.nameLong',
    eventName: 'eventTypeData.lunch.eventName',
    timing: 'eventTypeData.lunch.timing',
    poolCloseHour: REGISTRATION_HOUR_CLOSE_LUNCH,
    eventDescription: city => `eventTypeData.lunch.eventDescription`,
    howDescription: weekday => `eventTypeData.lunch.howDescription`,
    howDescription2: city =>
      `Meet someone new at a surprise restaurant in ${city.name}. Who you're meeting and where you're dining is based on your matching preferences.`,
    locationText: cityName => `eventTypeData.lunch.locationText`,
    peopleText: 'eventTypeData.lunch.peopleText',
    venue: 'restaurant',
    attendeesRange: '2',
    fieldName: 'lunch',
    genderMixField: 'gender_mix_lunch',
    how: HOW_COFFEE,
  },
  [EVENT_TYPE.COFFEE]: {
    eventType: EVENT_TYPE.COFFEE,
    nameShort: 'coffee',
    nameMixed: 'coffee/lunch',
    themes_type: EVENT_TYPE.LUNCH,
    nameLong: 'coffee 1-1',
    eventName: 'Coffee',
    timing: 'today',
    poolCloseHour: REGISTRATION_HOUR_CLOSE_COFFEE,
    eventDescription: city => 'Meet someone new for coffee.',
    howDescription: weekday => "Register before 10am and we'll match you with someone new for coffee.",
    howDescription2: city =>
      "Meet someone new at a surprise coffee shop in ${city.name}. Who you're meeting and where you're dining is based on your matching preferences.",
    locationText: cityName => 'A surprise coffee shop chosen based on your preferences and wishlist.',
    peopleText: "We'll match you for a 1:1 coffee meeting according to your preferences.",
    venue: 'coffee shop',
    attendeesRange: '2',
    fieldName: 'coffee',
    genderMixField: 'gender_mix_lunch',
    how: HOW_COFFEE,
  },
};

export const getEventTypeData = eventType => {
  return eventType ? EVENT_TYPE_DATA[eventType] : POOL_PLANNED_DATA;
};
